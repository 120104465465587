import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

const IubendaScript = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const currentLocale = i18n.language;

    // Crea lo script di configurazione iubenda
    const configScript = document.createElement("script");
    configScript.type = "text/javascript";
    configScript.innerHTML = `
      var _iub = _iub || [];
      _iub.csConfiguration = {
        "askConsentAtCookiePolicyUpdate": true,
        "cookiePolicyInOtherWindow": true,
        "countryDetection": true,
        "enableFadp": true,
        "enableLgpd": true,
        "enableUspr": true,
        "lgpdAppliesGlobally": false,
        "perPurposeConsent": true,
        "reloadOnConsent": true,
        "siteId": 3300148,
        "whitelabel": false,
        "cookiePolicyId": 75371987,
        "lang": "${currentLocale}",
        "banner": {
          "acceptButtonCaptionColor": "#ffffff",
          "acceptButtonColor": "#e93368",
          "acceptButtonDisplay": true,
          "backgroundColor": "#FFFFFF",
          "backgroundOverlay": true,
          "closeButtonDisplay": false,
          "customizeButtonCaptionColor": "#FFFFFF",
          "customizeButtonColor": "#0B1152",
          "customizeButtonDisplay": true,
          "explicitWithdrawal": true,
          "fontSizeBody": "16px",
          "listPurposes": true,
          "linksColor": "#e93368",
          "position": "float-center",
          "rejectButtonCaptionColor": "#ffffff",
          "rejectButtonColor": "#e93368",
          "rejectButtonDisplay": true,
          "showPurposesToggles": true,
          "textColor": "#0B1152",
          "borderRadius":"15px",
        }
      };
    `;
    document.body.appendChild(configScript);

    // Crea e aggiungi gli altri script iubenda
    const stubScript = document.createElement("script");
    stubScript.type = "text/javascript";
    stubScript.src = "//cdn.iubenda.com/cs/gpp/stub.js";

    const csScript = document.createElement("script");
    csScript.type = "text/javascript";
    csScript.src = "//cdn.iubenda.com/cs/iubenda_cs.js";
    csScript.charset = "UTF-8";
    csScript.async = true;

    document.body.appendChild(stubScript);
    document.body.appendChild(csScript);

    // Funzione per applicare stili personalizzati
    const applyCustomStyles = () => {
      const banner = document.querySelector(
        "#iubenda-cs-banner.iubenda-cs-default-floating .iubenda-cs-content"
      );
      if (banner) {
        banner.style.setProperty("border-radius", "15px", "important");
        banner.style.setProperty("margin", "16px", "important");
        banner.style.setProperty("font-family", "Fredoka", "important");
        banner.style.fontFamily="Fredoka"
      }

      const elements = document.querySelectorAll(
        "#iubenda-cs-banner .iubenda-banner-content:not(.iubenda-custom-content) *, #iubenda-cs-banner [class*=' iub'], #iubenda-cs-banner [class^=iub]"
      );
      elements.forEach((el) => {
        el.style.setProperty(
          "font-family",
          "'Fredoka', sans-serif",
          "important"
        );
      });
    };

    // Applica gli stili dopo che il banner è stato caricato
    const observer = new MutationObserver((mutations, obs) => {
      const bannerExists = document.querySelector(
        "#iubenda-cs-banner.iubenda-cs-default-floating .iubenda-cs-content"
      );
      if (bannerExists) {
        applyCustomStyles();
        obs.disconnect(); // Interrompi l'osservazione dopo aver applicato gli stili
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    // Pulizia alla dismissione del componente
    return () => {
      document.body.removeChild(configScript);
      document.body.removeChild(stubScript);
      document.body.removeChild(csScript);
      observer.disconnect();
    };
  }, [i18n.language]); // Riesegui l'effetto quando il locale cambia

  return null; // Questo componente non rende nulla
};

export default IubendaScript;
