

import React from "react";
import CountryFlagSvg from "country-list-with-dial-code-and-flag/dist/flag-svg";
import { Image, createStyles } from "@mantine/core";

function CountryFlag({ countryCode }) {

  const useStyles = createStyles((theme) => ({
    box:{
      borderRadius:"50%",
      width:"25px",
    }
  }));
  const { classes } = useStyles();

  const svgString = CountryFlagSvg[countryCode];

  // Codifica l'immagine SVG come data URI
  const svgDataUri = `data:image/svg+xml,${encodeURIComponent(svgString)}`;

  return (
    <div className={classes.box}>

      <Image
        src={svgDataUri}
        alt={`Flag of ${countryCode}`}
        boxSize="100%" // Imposta sia la larghezza che l'altezza al 100% della Box
        objectFit="cover" // Garantisce che l'immagine copra l'intero contenitore
        objectPosition="center" // Centra l'immagine all'interno del contenitore
      />
    </div>
  );
}

export default CountryFlag;

