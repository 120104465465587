import * as React from "react";
import UserDashboardWrapper from "../ReusableComponents/UserDashboardWrapper";
import DashboardStats from "../MainDashboard/DashboardStats"
import QrCodeCard from "../MainDashboard/QrCodeCard";
import ShareBox from "../MainDashboard/ShareBox";
import { useUser } from "../../AuthProvider/UserContext";
import Closbox from "../../Api/Closbox";
import {
  Title,
  Loader,
  Container,
  Paper,
  Grid,
  Col,
} from "@mantine/core";
import CouponCard from "../MainDashboard/CouponCard";

export default function Dashboard() {

  const{userData, setUserData} = useUser();
  const[isLoading, setIsLoading]=React.useState(true);
  const [affiliationData, setAffiliationData] = React.useState(null);

  React.useEffect(() => {
    // Controllo iniziale per dati nel sessionStorage
    const savedData = sessionStorage.getItem("affiliationData");
    if (savedData) {
      setAffiliationData(JSON.parse(savedData));
    }

    const fetchAffiliateData = () => {
      const closboxApi = Closbox.getInstance();
      closboxApi.setAuthToken(userData.token);

      closboxApi
        .syncAffiliate(userData.user.id)
        .then((response) => {

          const data = response.data;
          setUserData({user:userData.user, token:userData.token, company:data.company})
          sessionStorage.setItem("affiliationData", JSON.stringify(data));
          setAffiliationData(data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Errore durante il caricamento dei dati:", error);

          // Se la chiamata fallisce e non abbiamo dati nel sessionStorage
          if (!savedData) {
            setIsLoading(false);
          }
        });
    };

    fetchAffiliateData();
  }, []);



  let data= null

  if(affiliationData){
    data = [
      {
        title: "Monthly Qr Code Scan",
        icon: "qr",
        value: affiliationData.linkUsageCount,
        diff: "",
      },
      {
        title: "Monthly earnings",
        icon: "earnings",
        value: new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(affiliationData.totalCommissionCurrentMonth),
        diff: affiliationData.commissionChangePercentage,
      },
      {
        title: "Bookings",
        icon: "booking",
        value: affiliationData.totalBookingsCurrentMonth,
        diff: affiliationData.bookingsChangePercentage,
      },
      //... puoi aggiungere altri oggetti qui seguendo la stessa struttura
    ];
  }

  if (isLoading || !data) {
    return (
      <Container
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper padding="lg" style={{ textAlign: "center" }}>
          <Loader size="xl" color="danger.9"/>
        </Paper>
      </Container>
    );
  }

  return (
    <>
      <UserDashboardWrapper>
        <Title size="h1" color="primary.9">
          Welcome back {userData.user.name.split(" ")[0]} &#x1F44B; ,
        </Title>

        <Container size="xxl">
          <DashboardStats data={data} />
          <Grid>
            <Col sm={12} lg={4} radius="lg">
              <CouponCard couponCode={affiliationData.couponCode} />
            </Col>
            <Col sm={12} lg={4} radius="lg">
              <QrCodeCard qrCodeLink={affiliationData.qrCodeLink} />
            </Col>
            <Col sm={12} lg={4} radius="lg">
              <ShareBox link={affiliationData.affiliationLink} />
            </Col>
          </Grid>
        </Container>
      </UserDashboardWrapper>
    </>
  );
}
