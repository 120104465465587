
import "./Styles/App.css";
import LoginForm from "./Components/Auth/LoginForm";
import RegisterForm from "./Components/Auth/RegisterForm";
import WelcomePage from "./Components/Pages/WelcomePage"
import MainDashboard from "./Components/Pages/MainDashboard"
import Billing from "./Components/Pages/Billing";
import Bookings from "./Components/Pages/Bookings";
import Account from "./Components/Pages/Account";
import { UserProvider } from "./AuthProvider/UserContext";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useUser } from "./AuthProvider/UserContext";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import SeoHelmet from "./Components/Seo/SeoHelmet"
import IubendaScript from "./Components/ReusableComponents/Iubenda";
import { TermsAndConditionsScreen } from "./Components/Pages/TermsAndConditions";


function App() {
  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        colorScheme: "light",
        colors: {
          primary: [
            "#171d5b",
            "#30356c",
            "#484c7d",
            "#60648f",
            "#797ca0",
            "#9194b1",
            "#aaacc2",
            "#c2c3d4",
            "#dadbe5",
            "#0b1152",
          ],
          neutral: [
            "#ffffff",
            "#ffffff",
            "#ffffff",
            "#ffffff",
            "#ffffff",
            "#ffffff",
            "#ffffff",
            "#ffffff",
            "#ffffff",
            "white",
          ],
          danger: [
            "#ea3d70",
            "#ec527f",
            "#ee668e",
            "#f17a9d",
            "#f38fac",
            "#f5a3bb",
            "#f7b8ca",
            "#faccd9",
            "#fce0e8",
            "#e93368",
          ],
          warning: [
            "#fbaf2b",
            "#fcb841",
            "#fcc058",
            "#fcc86e",
            "#fdd184",
            "#fdd99b",
            "#fee2b1",
            "#feeac7",
            "#fef2de",
            "#fbab20",
          ],
          success: [
            "#0dffc8",
            "#26ffce",
            "#40ffd4",
            "#59ffd9",
            "#73ffdf",
            "#8cffe5",
            "#a6ffeb",
            "#bffff0",
            "#d9fff6",
            "#00ffc5",
          ],
        },

        shadows: {
          md: "1px 1px 3px rgba(0, 0, 0, .25)",
          xl: "5px 5px 3px rgba(0, 0, 0, .25)",
        },

        headings: {
          fontFamily: "'Inter', sans-serif",
          sizes: {
            h1: { fontSize: "2rem" },
            // Puoi aggiungere altre dimensioni se ne hai bisogno
          },
        },
      }}
    >
      <BrowserRouter>
        <UserProvider>
          <Content />
        </UserProvider>
      </BrowserRouter>
      <Notifications />
    </MantineProvider>
  );
}

function Content() {
  const { isAuthenticated} = useUser();

  return (
    <div className="App">
      <IubendaScript />
      <SeoHelmet />
      <Routes>
        <Route path="/" element={<WelcomePage />} />
        <Route
          path="/login"
          element={
            isAuthenticated ? <Navigate to="/dashboard" /> : <LoginForm />
          }
        />

        <Route
          path="/signup"
          element={
            isAuthenticated ? <Navigate to="/dashboard" /> : <RegisterForm />
          }
        />
        <Route
          path="/dashboard"
          element={isAuthenticated ? <MainDashboard /> : <Navigate to="/" />}
        />

        <Route
          path="/bookings"
          element={isAuthenticated ? <Bookings /> : <Navigate to="/login" />}
        />

        <Route
          path="/billing"
          element={isAuthenticated ? <Billing /> : <Navigate to="/login" />}
        />
        <Route
          path="/account"
          element={isAuthenticated ? <Account /> : <Navigate to="/login" />}
        />
        <Route
          path="/terms-and-conditions"
          element={<TermsAndConditionsScreen/>}
        />
      </Routes>
    </div>
  );
}

export default App;