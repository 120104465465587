import React, { createContext, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  // Inizializza lo stato con i dati da sessionStorage o con valori di default
  const navigate = useNavigate();
  const [userData, setUserData] = useState(() => {
    const storedData = sessionStorage.getItem("user");
    return storedData ? JSON.parse(storedData) : { user: null, token: null, company:null, };
  });


  // Calcola il valore di isAuthenticated
  const isAuthenticated = Boolean(userData.user && userData.token);

  // Ogni volta che userData cambia, aggiorna sessionStorage
  useEffect(() => {
    if (userData) {
      sessionStorage.setItem("user", JSON.stringify(userData));
    } else {
      sessionStorage.removeItem("user");
    }
  }, [userData]);

  const logout = () => {
    // Pulisci lo stato
    setUserData({ user: null, token: null, company: null });

    // Rimuovi i dati dalla sessionStorage
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("affiliationData");

    navigate("/");
  };

  return (
    <UserContext.Provider value={{ userData, setUserData, isAuthenticated, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
