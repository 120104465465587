import {
  createStyles,
  Badge,
  Group,
  Title,
  Text,
  Card,
  Container,
  rem,
  Grid,
  Divider,
  ThemeIcon,
  List,
  Button,
} from "@mantine/core";

import { IconQrcode, IconHeartHandshake, IconCheck } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ContactForm from "../ReusableComponents/ContactForm";

const useStyles = createStyles((theme) => ({
  container: {
    marginTop: "45px!important",
    background: theme.colors.danger[9],
  },

  badge: {
    color: theme.colors.neutral,
    backgroundColor: theme.colors.primary[9],
  },

  title: {
    color: theme.colors.neutral,
    fontSize: rem(45),
    lineHeight: 1.2,
    fontWeight: 900,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(32),
      textAlign: "center",
    },
  },

  listItem: {
    color: theme.colors.primary[9],
  },
}));

export default function Contacts() {
  const { classes, theme } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();


  return (
    <>
      <Container size="full" className={classes.container}>
        <Container size="lg" py="50px">
                <Badge
                variant="filled"
                size="lg"
                color="warning.9"
                className={classes.badge}
                mb={25}
                >
                {t("homepage.contacts.badge")}
                </Badge>
                <Title
                order={1}
                size="h1"
                color="primary.9"
                className={classes.title}
                >
                {t("homepage.contacts.title")}
                </Title>

                <Card
                    key="1"
                    shadow="md"
                    radius="lg"
                    className={classes.card}
                    padding="xl"
                    mt="30px"
                >
                    <ContactForm/>
                </Card>
        </Container>
      </Container>
    </>
  );
}
