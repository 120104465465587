import {
  createStyles,
  Badge,
  Group,
  Title,
  Text,
  Card,
  SimpleGrid,
  Container,
  rem,
} from "@mantine/core";
import { IconClick, IconCoin, IconHelpHexagon } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";



const useStyles = createStyles((theme) => ({
  title: {
    fontSize: rem(34),
    fontWeight: 900,

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(24),
    },
  },

  description: {
    maxWidth: 600,
    margin: "auto",

    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.colors.success,
      width: rem(45),
      height: rem(2),
      marginTop: theme.spacing.sm,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },

  card: {
    border: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[1]
    }`,
  },

  cardTitle: {
    "&::after": {
      content: '""',
      display: "block",
      backgroundColor: theme.colors.success,
      width: rem(45),
      height: rem(2),
      marginTop: theme.spacing.sm,
    },
  },
}));

export default function ProgramFeatures() {
  const { classes, theme } = useStyles();
  const {t} = useTranslation();

  const mockdata = [
    {
      title: t("homepage.programFeatures.card-1.title"),
      description: t("homepage.programFeatures.card-1.content"),
      icon: IconClick,
    },
    {
      title: t("homepage.programFeatures.card-2.title"),
      description: t("homepage.programFeatures.card-2.content"),
      icon: IconHelpHexagon,
    },
    {
      title: t("homepage.programFeatures.card-3.title"),
      description: t("homepage.programFeatures.card-3.content"),
      icon: IconCoin,
    },
  ];

  const features = mockdata.map((feature) => (
    <Card
      key={feature.title}
      shadow="md"
      radius="lg"
      className={classes.card}
      padding="xl"
    >
      <feature.icon size={rem(50)} stroke={2} color={theme.colors.danger[9]} />
      <Text fz="lg" fw={500} className={classes.cardTitle} mt="md" color="primary.9">
        {feature.title}
      </Text>
      <Text fz="sm" c="dimmed" mt="sm">
        {feature.description}
      </Text>
    </Card>
  ));

  return (
    <Container size="lg" py="xl">
      <Group position="center" mb={8}>
        <Badge variant="filled" size="lg" color="danger.9">
          {t("homepage.programFeatures.badge")}
        </Badge>
      </Group>

      <Title order={2} className={classes.title} ta="center" color="primary.9">
        {t("homepage.programFeatures.title")}
      </Title>

      <Text
        c="dimmed"
        className={classes.description}
        ta="center"
        mt="md"
        fz="l"
      >
        {t("homepage.programFeatures.subtitle")}
      </Text>

      <SimpleGrid
        cols={3}
        spacing="xl"
        mt={50}
        breakpoints={[{ maxWidth: "md", cols: 1 }]}
      >
        {features}
      </SimpleGrid>
    </Container>
  );
}
