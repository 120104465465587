import * as React from "react";
import UserDashboardWrapper from "../ReusableComponents/UserDashboardWrapper";
import { useUser } from "../../AuthProvider/UserContext";
import Closbox from "../../Api/Closbox";
import { useForm } from "@mantine/form";
import { useNavigate } from "react-router-dom";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconAlertHexagonFilled } from "@tabler/icons-react";
import {
  Title,
  Loader,
  Container,
  Paper,
  Group,
  Text,
  Divider,
  Grid,
  Col,
  Image,
  Card,
  Button,
  Input,
} from "@mantine/core";

export default function Bookings() {
    const { userData, setUserData } = useUser();
    const [affiliationData, setAffiliationData] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const navigate = useNavigate();
    const savedData = sessionStorage.getItem("affiliationData");
    const closboxApi = Closbox.getInstance();
    const fetchAffiliateData = () => {
      closboxApi.setAuthToken(userData.token);

      closboxApi
        .syncAffiliate(userData.user.id)
        .then((response) => {
          const data = response.data;
          setUserData({
            user: userData.user,
            token: userData.token,
            company: data.company,
          });
          sessionStorage.setItem("affiliationData", JSON.stringify(data));
          setAffiliationData(data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Errore durante il caricamento dei dati:", error);

          // Se la chiamata fallisce e non abbiamo dati nel sessionStorage
          if (!savedData) {
            setIsLoading(false);
          }
        });
    };

    

    const form = useForm({
        initialValues: {
        // Account details
        affiliateName: userData.user.name || "",
        affiliateEmail: userData.user.email || "",
        affiliatePhone: userData.user.phone_number || "",

        // Company details
        companyName: userData.company.name || "",
        companyAddress: userData.company.address || "",
        companyCity: userData.company.city || "",
        companyCountry: userData.company.country || "",
        companyZipCode: userData.company.zip_code || "",
        companyLegalMail: userData.company.legal_mail || "",

        // Fiscal details
        vatNumber: userData.company.vat_number || "",
        fiscalCode: userData.company.fiscal_code || "",

        // Payment details
        ibanNumber: userData.company.iban || "",
        bicCode: userData.company.bic || "",
        bankName: userData.company.bank_name || "",
        },

        // Omettendo la validazione per ora, ma puoi includerla
    });

    React.useEffect(() => {
        // Controllo iniziale per dati nel sessionStorage
        
        if (savedData) {
        setAffiliationData(JSON.parse(savedData));
        }

        fetchAffiliateData();
    }, []);


    let data = null;

    if (affiliationData) {
        data = affiliationData.allCommissionRecords;
    }
    //Showing a loader until all the data are not fetched properly.

    if (isLoading && !data) {
        return (
        <Container
            style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            }}
        >
            <Paper padding="lg" style={{ textAlign: "center" }}>
            <Loader size="xl" color="danger.9" />
            </Paper>
        </Container>
        );
    }

    const toDashboard = (event) => {
        event.preventDefault();

        navigate("/dashboard");
    };

    const saveDetails = (values)=>{
        setIsLoading(true)
        
        closboxApi.updateAffiliateData(values, userData.company.id, userData.user.id)
        .then((response)=>{

            

            if(response.message === "Ok"){

                
                setIsLoading(false);
                notifications.show({
                  title: "Account details updated",
                  message:"Your account details have been successfully updated",
                  color: "success.9",
                  icon: <IconCheck />,
                  radius:"lg",
                });

                fetchAffiliateData();

            }else{

                setIsLoading(false);
                notifications.show({
                  title: "An error occured",
                  message:
                    "Something went wrong with your request. Try again soon",
                  color: "danger.9",
                  icon: <IconAlertHexagonFilled />,
                });

                fetchAffiliateData();

            }
        })
    }

    




  return (
    <>
      <UserDashboardWrapper>
        <Container fluid>
          <Grid gutter="md">
            <Col span={12}>
              <Title size="h1" color="primary.9">
                &#x1F984; Your profile info
              </Title>
            </Col>
          </Grid>

          <form onSubmit={form.onSubmit(saveDetails)}>
            <Paper radius={"lg"} p={"lg"} my={25}>
              <Title size="h3" color="primary.9">
                👨 Account details
              </Title>
              <Grid my={20}>
                <Grid.Col sm={12} md={6} xl={4}>
                  <Input.Wrapper
                    id="input-affiliate-name"
                    label="Your name"
                    description="Full name of the account manager"
                  >
                    <Input
                      id="input-affiliate-name"
                      placeholder="Your full name"
                      radius="md"
                      size="lg"
                      {...form.getInputProps("affiliateName")}
                    />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col sm={12} md={6} xl={4}>
                  <Input.Wrapper
                    id="input-affiliate-email"
                    label="Your email address"
                    description="The email address for login"
                  >
                    <Input
                      id="input-affiliate-email"
                      placeholder="Your email"
                      radius="md"
                      size="lg"
                      {...form.getInputProps("affiliateEmail")}
                    />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col sm={12} md={6} xl={4}>
                  <Input.Wrapper
                    id="input-affiliate-phone"
                    label="Your phone number"
                    description="Account manager phone number"
                  >
                    <Input
                      id="input-affiliate-phone"
                      placeholder="Your phone number"
                      radius="md"
                      size="lg"
                      {...form.getInputProps("affiliatePhone")}
                    />
                  </Input.Wrapper>
                </Grid.Col>
              </Grid>
            </Paper>
            <Paper radius={"lg"} p={"lg"} my={25}>
              <Title size="h3" color="primary.9">
                👷 Company details
              </Title>
              <Text color="dimmed" mt={15}>
                Please provide the following information about your company.
                Include only informations that are verifiable from the
                registration documents.
              </Text>
              <Divider
                my="lg"
                label="General details"
                color="primary.9"
                size="md"
              />
              <Grid my={20}>
                <Grid.Col sm={12} md={6} xl={4}>
                  <Input.Wrapper
                    id="input-affiliate-company-name"
                    label="Company name"
                    description="The registered full company name"
                  >
                    <Input
                      id="input-affiliate-company-name"
                      placeholder="Company name"
                      radius="md"
                      size="lg"
                      {...form.getInputProps("companyName")}
                    />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col sm={12} md={6} xl={4}>
                  <Input.Wrapper
                    id="input-affiliate-company-address"
                    label="Company address"
                    description="Registered company address"
                  >
                    <Input
                      id="input-affiliate-company-address"
                      placeholder="Registered address of your company"
                      radius="md"
                      size="lg"
                      {...form.getInputProps("companyAddress")}
                    />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col sm={12} md={6} xl={4}>
                  <Input.Wrapper
                    id="input-affiliate-company-city"
                    label="City"
                    description="Registered city"
                  >
                    <Input
                      id="input-affiliate-company-city"
                      placeholder="City"
                      radius="md"
                      size="lg"
                      {...form.getInputProps("companyCity")}
                    />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col sm={12} md={6} xl={4}>
                  <Input.Wrapper
                    id="input-affiliate-company-country"
                    label="Country"
                    description="The country where the company is registered"
                  >
                    <Input
                      id="input-affiliate-company-country"
                      placeholder="The country where the company is registered"
                      radius="md"
                      size="lg"
                      {...form.getInputProps("companyCountry")}
                    />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col sm={12} md={6} xl={4}>
                  <Input.Wrapper
                    id="input-affiliate-company-address-zipcode"
                    label="Zip Code"
                    description="Zip code of the registered address"
                  >
                    <Input
                      id="input-affiliate-company-address-zipcode"
                      placeholder="Zip code for the registered company address"
                      radius="md"
                      size="lg"
                      {...form.getInputProps("companyZipCode")}
                    />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col sm={12} md={6} xl={4}>
                  <Input.Wrapper
                    id="input-affiliate-company-legalmail"
                    label="Legal mail"
                    description="The registered legal mail address of the company"
                  >
                    <Input
                      id="input-affiliate-company-legalmail"
                      placeholder="Company legal mail"
                      radius="md"
                      size="lg"
                      type="email"
                      {...form.getInputProps("companyLegalMail")}
                    />
                  </Input.Wrapper>
                </Grid.Col>
              </Grid>
              <Divider
                my="lg"
                label="Fiscal details"
                color="primary.9"
                size="md"
              />
              <Grid my={20}>
                <Grid.Col sm={12} md={6} xl={4}>
                  <Input.Wrapper
                    id="input-affiliate-vat"
                    label="VAT Number"
                    description="Full VAT number including the country code"
                  >
                    <Input
                      id="input-affiliate-vat"
                      placeholder="Company VAT Number"
                      {...form.getInputProps("vatNumber")}
                      radius="md"
                      size="lg"
                    />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col sm={12} md={6} xl={4}>
                  <Input.Wrapper
                    id="input-affiliate-fiscal-code"
                    label="Fiscal Code"
                    description="Only for Italian registered companies"
                  >
                    <Input
                      id="input-affiliate-fiscal-code"
                      placeholder="Registered address of your company"
                      {...form.getInputProps("fiscalCode")}
                      radius="md"
                      size="lg"
                    />
                  </Input.Wrapper>
                </Grid.Col>
              </Grid>
              <Divider
                my="lg"
                label="Payment details"
                color="primary.9"
                size="md"
              />
              <Grid my={20}>
                <Grid.Col sm={12} md={6} xl={4}>
                  <Input.Wrapper
                    id="input-iban-number"
                    label="Iban"
                    description="Your company IBAN to receive the payments"
                  >
                    <Input
                      id="input-iban-number"
                      placeholder="Company IBAN number"
                      {...form.getInputProps("ibanNumber")}
                      radius="md"
                      size="lg"
                    />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col sm={12} md={6} xl={4}>
                  <Input.Wrapper
                    id="input-affiliate-bic-code"
                    label="BIC code"
                    description="Your bank BIC code"
                  >
                    <Input
                      id="input-affiliate-bic-code"
                      placeholder="Bank BIC code"
                      {...form.getInputProps("bicCode")}
                      radius="md"
                      size="lg"
                    />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col sm={12} md={6} xl={4}>
                  <Input.Wrapper
                    id="input-affiliate-bank-name"
                    label="Bank name"
                    description="Your bank name"
                  >
                    <Input
                      id="input-affiliate-bank-name"
                      placeholder="Bank name"
                      {...form.getInputProps("bankName")}
                      radius="md"
                      size="lg"
                    />
                  </Input.Wrapper>
                </Grid.Col>
              </Grid>
            </Paper>
            <Grid justify={"end"}>
              <Grid.Col  lg={2}>
                <Button
                  color="primary.9"
                  variant="outline"
                  radius={"xl"}
                  size="lg"
                  onClick={toDashboard}
                  fullWidth
                >
                  Back to dashboard
                </Button>
              </Grid.Col>
              <Grid.Col lg={2}>
                <Button
                fullWidth
                  color="primary.9"
                  radius={"xl"}
                  type="submit"
                  size="lg"
                  loading={isLoading}
                >
                  Save details
                </Button>
              </Grid.Col>
            </Grid>
          </form>
        </Container>
      </UserDashboardWrapper>
    </>
  );
}
