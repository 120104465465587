import React from "react";
import {
  Container,
  Text,
  Title,
  List,
  Anchor,
  Box,
  Loader,
} from "@mantine/core";
import Closbox from "../../Api/Closbox";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Navbar from "../ReusableComponents/Navbar";

function parseHtmlToMantine(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");

  const parseNode = (node) => {
    if (node.nodeType === Node.TEXT_NODE) {
      return node.nodeValue;
    }

    let children = Array.from(node.childNodes).map(parseNode);

    switch (node.nodeName) {
      case "H1":
      case "H2":
      case "H3":
      case "H4":
      case "H5":
      case "H6":
        return (
          <Title order={3} my="md">
            {children}
          </Title>
        );
      case "P":
        return <Text my="sm">{children}</Text>;
      case "OL":
        return <List type="ordered">{children}</List>;
      case "UL":
        return <List>{children}</List>;
      case "LI":
        return <List.Item>{children}</List.Item>;
      case "A":
        return (
          <Anchor href={node.getAttribute("href")} target="_blank">
            {children}
          </Anchor>
        );
      // Gestisci altri tag HTML se necessario
      default:
        return <Box>{children}</Box>;
    }
  };

  return parseNode(doc.body);
}

const TermsAndConditionsScreen = () => {
  const closbox = Closbox.getInstance();
  const [body, setBody] = React.useState("");
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    setIsLoading(true);
    closbox
      .getPolicy(2)
      .then((res) => {
        if (res.body) {
          setBody(res.body.it);
        }
      })
      .catch((e) => {
        console.error(e);
        navigate("/");
      })
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <Container size="lg" mt="xl">
      <Navbar />
      <Box my="md">
        <Title order={1}>{t("termsConditions.title")}</Title>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "60vh",
            }}
          >
            <Loader size="lg" />
          </Box>
        ) : (
          <Text>{parseHtmlToMantine(body)}</Text>
        )}
      </Box>
    </Container>
  );
};

export { TermsAndConditionsScreen };
