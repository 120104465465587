import React from "react";
import i18n from "i18next";
import { initReactI18next, useTranslation } from "react-i18next";
import enTranslation from "./Translations/en/translation.json";
import itTranslation from "./Translations/it/translation.json";
import LanguageDetector from "i18next-browser-languagedetector";
import CountryFlag from "./Components/ReusableComponents/CountryFlag"; // Ensure you have flag images for GB and IT.
import { Button, Menu, Text, useMantineTheme } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      it: {
        translation: itTranslation,
      },
      en: {
        translation: enTranslation,
      },
    },
    fallbackLng: "en", // Fallback to English if the detected language is not available
    interpolation: {
      escapeValue: false, // React already safes from xss
    },
    detection: {
      order: ["querystring", "cookie", "localStorage", "navigator", "htmlTag"],
      caches: ["cookie"], // Specify where to cache the language
    },
  });

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const theme = useMantineTheme();

  return (
    <Menu
      transition="pop-top-right"
      position="top-end"
      width={200}
      withinPortal
    >
      <Menu.Target>
        <Button
          color="neutral"
          style={{ borderRadius: "15px", alignItems: "center" }}
        >
          <CountryFlag
            countryCode={i18n.language.startsWith("it") ? "IT" : "GB"}
          />
        </Button>
      </Menu.Target>
      <Menu.Dropdown style={{borderRadius:"15px"}}>
        <Menu.Item onClick={() => changeLanguage("en")}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <CountryFlag countryCode="GB" />
            <Text ml={4}>English</Text>
          </div>
        </Menu.Item>
        <Menu.Item onClick={() => changeLanguage("it")}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <CountryFlag countryCode="IT" />
            <Text ml={4}>Italiano</Text>
          </div>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export { LanguageSwitcher };
