import React from "react";
import {
  Grid,
  TextInput,
  Select,
  Textarea,
  Checkbox,
  Button,
  useMantineTheme,
  Text,
  Title,
  Container,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useTranslation } from "react-i18next";
import { IconCheck } from "@tabler/icons-react";
import ClosboxV2 from "../../Api/ClosboxV2"; // Importa la classe ClosboxV2

export default function ContactForm() {
  const theme = useMantineTheme();
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [formSubmitted, setFormSubmitted] = React.useState(false);

  // Inizializza l'istanza di ClosboxV2
  const closboxInstance = ClosboxV2.getInstance();

  const form = useForm({
    initialValues: {
      name: "",
      surname: "", // Aggiunto il campo cognome
      email: "",
      phone: "", // Aggiunto il campo telefono
      programType: "",
      subject: "",
      message: "",
      privacy: false,
    },
  });

  const handleSubmit = async (values) => {
    setIsLoading(true);

    // Ottieni il locale corrente dall'istanza di i18next
    const currentLocale = i18n.language;

    try {
      // Modifica il messaggio per includere il "Programma di affiliazione richiesto" all'inizio
      const modifiedMessage = `Programma di affiliazione richiesto: ${values.programType}\n\n${values.message}`;

      // Esegui la chiamata al metodo createTicket di ClosboxV2
      const response = await closboxInstance.createTicket(
        values.name,
        values.surname, // Cognome
        values.email,
        values.phone, // Numero di telefono
        null, // Numero di prenotazione inserito programmaticamente
        20, // Categoria o tipo di programma
        values.subject,
        modifiedMessage, // Passa il messaggio modificato
        currentLocale // Locale dinamico ottenuto da i18next
      );
      console.log(response);
      setFormSubmitted(true); // Imposta lo stato a true per indicare che il form è stato inviato
    } catch (error) {
      console.error("Errore durante la creazione del ticket:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const inputStyles = { input: { borderRadius: theme.radius.md } };

  if (formSubmitted) {
    return (
      <Container
        size="sm"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          padding: "40px",
        }}
      >
        <IconCheck
          size={50}
          stroke={1.5}
          color={theme.colors.green[6]}
          style={{ marginBottom: "20px" }}
        />
        <Title
          order={2}
          color={theme.colors.primary[9]}
          style={{ textAlign: "center" }}
        >
          {t("contactForm.success.title")}
        </Title>
        <Text
          size="sm"
          color={theme.colors.primary[9]}
          style={{ marginTop: "10px", textAlign: "center" }}
        >
          {t("contactForm.success.subtitle")}
        </Text>
      </Container>
    );
  }

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Grid>
        <Grid.Col sm={6}>
          <TextInput
            required
            label={t("contactForm.labels.name")}
            placeholder={t("contactForm.placeholders.name")}
            {...form.getInputProps("name")}
            styles={inputStyles}
          />
        </Grid.Col>
        <Grid.Col sm={6}>
          <TextInput
            required
            label={t("contactForm.labels.surname")} // Aggiunto il campo cognome
            placeholder={t("contactForm.placeholders.surname")}
            {...form.getInputProps("surname")}
            styles={inputStyles}
          />
        </Grid.Col>
        <Grid.Col sm={6}>
          <TextInput
            required
            label={t("contactForm.labels.email")}
            placeholder={t("contactForm.placeholders.email")}
            {...form.getInputProps("email")}
            styles={inputStyles}
          />
        </Grid.Col>
        <Grid.Col sm={6}>
          <TextInput
            required
            label={t("contactForm.labels.phone")} // Aggiunto il campo numero di telefono
            placeholder={t("contactForm.placeholders.phone")}
            {...form.getInputProps("phone")}
            styles={inputStyles}
          />
        </Grid.Col>
        <Grid.Col sm={6}>
          <Select
            required
            label={t("contactForm.labels.program")}
            placeholder={t("contactForm.placeholders.program")}
            data={[
              { value: "gold", label: "Gold" },
              { value: "silver", label: "Silver" },
            ]}
            {...form.getInputProps("programType")}
            styles={inputStyles}
          />
        </Grid.Col>
        <Grid.Col sm={6}>
          <TextInput
            required
            label={t("contactForm.labels.subject")}
            placeholder={t("contactForm.placeholders.subject")}
            {...form.getInputProps("subject")}
            styles={inputStyles}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Textarea
            required
            label={t("contactForm.labels.message")}
            placeholder={t("contactForm.placeholders.message")}
            {...form.getInputProps("message")}
            styles={inputStyles}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Checkbox
            required
            label={t("contactForm.labels.terms")}
            {...form.getInputProps("privacy", { type: "checkbox" })}
            style={{ marginTop: "1em", marginBottom: "1em" }}
          />
        </Grid.Col>
        <Grid.Col span={12}>
          <Button
            type="submit"
            size="lg"
            loading={isLoading}
            styles={{
              root: {
                width: "100%",
                borderRadius: theme.radius.lg,
                backgroundColor: theme.colors.danger[9],
              },
            }}
          >
            {t("contactForm.labels.btn")}
          </Button>
        </Grid.Col>
      </Grid>
    </form>
  );
}
