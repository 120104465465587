import * as React from "react";
import UserDashboardWrapper from "../ReusableComponents/UserDashboardWrapper";
import { useUser } from "../../AuthProvider/UserContext";
import Closbox from "../../Api/Closbox";
import { parseISO, formatDistanceToNow } from "date-fns";
import { usePagination } from "@mantine/hooks";
import noEarnings from "../../Images/Illustrations/no-earnings.svg";
import PayoutRequestModal from "../Billing/PayoutRequestModal";
import { IconAlertCircle } from "@tabler/icons-react";
import {
  Title,
  Loader,
  Container,
  Paper,
  Avatar,
  Group,
  Text,
  Badge,
  Table,
  Pagination,
  Grid,
  Col,
  Image,
  Card,
  Tooltip,
  Alert,
} from "@mantine/core";

export default function Billing() {
  const { userData, setUserData } = useUser();
  const [affiliationData, setAffiliationData] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const ITEMS_PER_PAGE = 10;
  const [totalPages, setTotalPages] = React.useState(0);
  const savedData = sessionStorage.getItem("affiliationData");

  const fetchAffiliateData = () => {
    const closboxApi = Closbox.getInstance();
    closboxApi.setAuthToken(userData.token);

    closboxApi
      .syncAffiliate(userData.user.id)
      .then((response) => {
        const data = response.data;
        setUserData({
          user: userData.user,
          token: userData.token,
          company: data.company,
        });
        sessionStorage.setItem("affiliationData", JSON.stringify(data));
        setAffiliationData(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Errore durante il caricamento dei dati:", error);

        // Se la chiamata fallisce e non abbiamo dati nel sessionStorage
        if (!savedData) {
          setIsLoading(false);
        }
      });
  };

  React.useEffect(() => {
    // Controllo iniziale per dati nel sessionStorage
    if (savedData) {
      setAffiliationData(JSON.parse(savedData));
    }

    fetchAffiliateData();
  }, []);

  React.useEffect(() => {
    const totalRecords = affiliationData
      ? affiliationData.allCommissionRecords.length
      : 0;
    setTotalPages(Math.ceil(totalRecords / ITEMS_PER_PAGE));
  }, [affiliationData]);

  const {
    active: currentPage,
    setPage,
    ...paginationProps
  } = usePagination({
    initialPage: 1,
    total: totalPages,
    siblings: 1,
  });


  let unpaidRecords = [];

  let data = null;
  let totalAmountDue = 0;

  if (affiliationData) {
    data = affiliationData.allCommissionRecords;
    data.map((record) => {
      if (record.status === "confirmed") {

        totalAmountDue += record.commission_amount

        unpaidRecords.push(record);
      }
    });

    
  }

  const jobColors = {
    created: "primary.9",
    confirmed: "success.9",
    cancelled: "danger.9",
    pending:"gray.5",   
  };

  //Showing a loader until all the data are not fetched properly.

  if (isLoading || !data) {
    return (
      <Container
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Paper padding="lg" style={{ textAlign: "center" }}>
          <Loader size="xl" color="danger.9" />
        </Paper>
      </Container>
    );
  }

  // Calculate which records to display for the current page
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentRecords = affiliationData
    ? affiliationData.allPayoutRequests.slice(startIndex, endIndex)
    : [];

  //Creating dynamic table rows

  const rows = currentRecords.map((record) => {
    return (
      <tr key={record.booking_id}>
        <td>
          <Group gap="sm">
            <Avatar size={30} radius={30}>
              &#128640;
            </Avatar>
            <div>
                <Tooltip label={record.description}>

                    <Text fz="md" color="primary.9" fw={500} lineClamp={1}>
                        {record.description.length > 50
                        ? `${record.description.substring(0, 47)}...`
                        : record.description}
                    </Text>
                </Tooltip>
              <Text fz="xs" color="dimmed">
                Created {formatDistanceToNow(parseISO(record.created_at))} ago
              </Text>
            </div>
          </Group>
        </td>

        <td>
          <Badge
            color={jobColors[record.status.toLowerCase()]}
            variant="outline"
          >
            {record.status}
          </Badge>
        </td>
        <td>
          <Text fz="sm" color="primary.9">
            Total payout amount
          </Text>
          <Text fz="xs" color="dimmed">
            {new Intl.NumberFormat("it-IT", {
              style: "currency",
              currency: "EUR",
            }).format(record.amount)}
          </Text>
        </td>
        <td>
          <Text fz="sm" color="primary.9">
            Payout Unique ID
          </Text>
          <Text fz="xs" color="dimmed">
            {record.u_payout_id}
          </Text>
        </td>
        <td>
          <Text fz="sm" color="primary.9">
            Paid on
          </Text>
          <Text fz="xs" color="dimmed">
            {record.paid_on === null ? "Not paid yet" : record.paid_on}
          </Text>
        </td>
      </tr>
    );
  });

  return (
    <>
      <UserDashboardWrapper>
        <Container fluid>
          {userData.company.iban === null ? (
            <Alert
              icon={<IconAlertCircle size="2rem" />}
              title="You can't receive your payouts yet!"
              color="red"
              my={20}
              radius={"lg"}
            >
              It seems that you haven't set an IBAN address yet to receive payments. Head to the account section and setup a new IBAN address to receive your payouts.
            </Alert>
          ) : (
            ""
          )}
          <Grid gutter="md">
            <Col span={12}>
              <Title size="h1" color="primary.9">
                &#x1F4B8; Your payout requests
              </Title>
            </Col>
          </Grid>

          <Grid>
            <Col span={12}>
              {unpaidRecords.length > 0 ? (
                <Paper radius={"lg"} p={"lg"} mt={25}>
                  <Title size="h3" color="primary.9">
                    You can create a new payout request for &nbsp;
                    {new Intl.NumberFormat("it-IT", {
                      style: "currency",
                      currency: "EUR",
                    }).format(totalAmountDue)}
                  </Title>
                  <Text color="dimmed">
                    The payout request is the way you get your commissions paid.
                    Begin by issuing a new invoice to Closbox for &nbsp;
                    {new Intl.NumberFormat("it-IT", {
                      style: "currency",
                      currency: "EUR",
                    }).format(totalAmountDue)}{" "}
                    and once the invoice is ready and issued click the below
                    button.
                  </Text>
                  <PayoutRequestModal
                    data={affiliationData}
                    onSuccessfulPayout={fetchAffiliateData}
                  />
                </Paper>
              ) : (
                <Card style={{ textAlign: "center" }} radius="lg" my={25}>
                  <Grid>
                    <Grid.Col lg="4">
                      <Image
                        maw={250}
                        mx="auto"
                        radius="md"
                        src={noEarnings}
                        alt="No Earnings"
                      />
                    </Grid.Col>
                    <Grid.Col
                      lg="8"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <Title size="h2" color="primary.9">
                          You don't have any unclaimed amount currently
                          &#128577;
                        </Title>
                        <Text size="md" color="dimmed">
                          Promote your Qr Code to get new reservations and
                          commissions and come back soon.
                        </Text>
                      </div>
                    </Grid.Col>
                  </Grid>
                </Card>
              )}
            </Col>
          </Grid>
          {currentRecords.length > 0 ? (
            <>
              <Grid gutter="md" my={20}>
                <Col span={12}>
                  <Title size="h3" color="primary.9">
                    Payout history
                  </Title>
                </Col>
              </Grid>
              <div
                style={{
                  overflowX: "auto",
                  backgroundColor: "white",
                  marginTop: "25px",
                  borderRadius: "15px",
                }}
              >
                <Table
                  verticalSpacing="sm"
                  style={{ minWidth: "800px", whiteSpace: "nowrap" }}
                >
                  <tbody>{rows}</tbody>
                </Table>
              </div>
              <Grid gutter="md" justify="center">
                <Col span={12}>
                  <div style={{ marginTop: "20px" }}>
                    <Pagination
                      total={totalPages}
                      onChange={setPage}
                      radius={"md"}
                      color="danger.9"
                    />
                  </div>
                </Col>
              </Grid>
            </>
          ) : (
            <></>
          )}
        </Container>
      </UserDashboardWrapper>
    </>
  );
}



