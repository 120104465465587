import { createStyles, Container, Group, Anchor, rem, Text } from "@mantine/core";
import ClosboxLogo from "./ClosboxLogo";
import React from "react"
const loadScript = (src) => {
  const script = document.createElement("script");
  script.src = src;
  script.async = true;
  document.body.appendChild(script);
};

const useStyles = createStyles((theme) => ({

  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: theme.spacing.xl,
    paddingBottom: theme.spacing.xl,

    [theme.fn.smallerThan("xs")]: {
      flexDirection: "column",
    },
  },

  group: {
    marginRight: "45px",

    [theme.fn.smallerThan("xs")]: {
      marginRight: "0px",
    },
  },

  links: {
    [theme.fn.smallerThan("xs")]: {
      marginTop: theme.spacing.md,
    },
  },
}));

export default function Footer(props) {
  const { classes } = useStyles();


  React.useEffect(() => {
    loadScript("https://cdn.iubenda.com/iubenda.js");
  }, []);

  const items = props.links.map((link) => (


    <Anchor
      color="dimmed"
      key={link.label}
      href={link.link}
      className={link.className}
      size="sm"
    >
      {link.label}
    </Anchor>
  ));

  return (
    <div className={classes.footer}>
      <Container className={classes.inner}>
        <ClosboxLogo size={28} />
        <Group className={`${classes.links} ${classes.group}`}>{items}</Group>
        <Text color="primary.9">All rights reserved Closbox.com © 2024</Text>
      </Container>
    </div>
  );
}
