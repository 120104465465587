import * as React from "react";
import {
  Paper,
  Title,
  Text,
  CopyButton,
  ActionIcon,
  Tooltip,
  Flex,
} from "@mantine/core";
import SocialShareModal from "../ReusableComponents/SocialShareModal";

import { IconCopy, IconCheck} from "@tabler/icons-react";

export default function ShareBox({ link }) {

  return (
    <Paper shadow="lg" radius="lg" p="lg">
      <Title size="h3" color="primary.9">
        Share the link
      </Title>
      <Text color="dimmed" fz="sm" mt={5}>
        Share this link with your customers, friends or anybody else and
        everytime a new reservation is made through this link you will get a 10%
        commission on the booking amount.
      </Text>

      <Flex
        mih={50}
        bg="gray.1"
        gap="md"
        sx={{ borderRadius: "20px" }}
        justify="center"
        align="center"
        direction="row"
        wrap="wrap"
        my={20}
      >
        <Text color="dimmed">{link}</Text>
        <CopyButton value={link} timeout={2000}>
          {({ copied, copy }) => (
            <Tooltip
              label={copied ? "Copied" : "Copy"}
              withArrow
              position="right"
            >
              <ActionIcon color={copied ? "teal" : "gray"} onClick={copy}>
                {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
              </ActionIcon>
            </Tooltip>
          )}
        </CopyButton>
      </Flex>

      <SocialShareModal/>
    </Paper>
  );
}
