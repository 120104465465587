
import axios from "axios";

class Closbox {
  constructor() {
    const token = process.env.REACT_APP_CLOSBOX_TOKEN;
    this.client = axios.create({
      baseURL: process.env.REACT_APP_CLOSBOX_URL,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static instance;
  static getInstance() {
    if (!Closbox.instance) {
      Closbox.instance = new Closbox();
    }
    return Closbox.instance;
  }

  setAuthToken(token) {
    if (token) {
      this.client.defaults.headers["Authorization"] = `Bearer ${token}`;
    } else {
      delete this.client.defaults.headers["Authorization"];
    }
  }

  async login(email, password, source) {
    const response = await this.client.post("login", {
      email: email,
      password: password,
      source: source,
    });
    return response.data;
  }

  async register(data) {
    const response = await this.client.post("register", {
      fullName: data.name,
      phoneNumber: data.phoneNumber,
      email: data.email,
      password: data.password,
      role: data.role,
      languages: data.languages,
      companyName: data.companyName,
      companyAddress: data.companyAddress,
      city: data.city,
      zipCode: data.zipCode,
      country: data.country,
      vat: data.vat,
      fiscalCode: data.fiscalCode,
      legalMail: data.legalMail,
      companyEmail: data.companyEmail,
      type: data.type,
      privacyPolicy: data.privacyPolicy,
      termsAndConditions: data.termsAndConditions,
    });

    return response.data;
  }

  async pointOfSales() {
    const response = await this.client.get("point-of-sales");
    return response.data.data;
  }

  async faqs(locale) {
    const response = await this.client.get("get-faq", {
      params: { locale },
    });
    return response.data.data;
  }

  async postFaq(locale, title, email, name) {
    const response = await this.client.post("create-faq", {
      locale,
      title,
      from_email: email,
      from_username: name,
    });
    return response.data;
  }

  async getBooking(query) {
    const response = await this.client.get("get-booking", {
      params: query,
    });
    return response.data;
  }

  async getOriginalUrl(code) {
    const response = await this.client.get("url-shortner/get-original", {
      params: { code },
    });
    return response.data;
  }

  async pointOfSale(id) {
    const response = await this.client.get("point-of-sale", {
      params: { unique_code: id },
    });
    return response.data.data;
  }

  async checkAvailability(data) {
    const response = await this.client.get("get-availability", {
      params: data,
    });
    return response.data;
  }

  async getCloserAvailablePeriods(data) {
    const response = await this.client.get("get-closer-period", {
      params: data,
    });
    return response.data;
  }

  async extend(data) {
    const response = await this.client.put("extend-booking", { data });
    return response.data;
  }

  async delete(booking) {
    const response = await this.client.delete("cancel-booking", {
      params: booking,
    });
    return response.data;
  }

  async retrieveCredentials(booking) {
    const response = await this.client.get("retrieve-credentials", {
      params: booking,
    });
    return response.data;
  }

  async validateCoupon(coupon) {
    const response = await this.client.get("validate-coupon", {
      params: coupon,
    });
    return response.data;
  }

  async subscribe(data) {
    const response = await this.client.post("subscribe-newsletter", { data });
    return response.data;
  }

  async confirmSubscription(token) {
    const response = await this.client.post("confirm-subscription", {
      params: token,
    });
    return response.data;
  }

  async postTicket(data) {
    const response = await this.client.post("help-center/new-ticket", { data });
    return response.data;
  }

  async getPolicy(id) {
    const response = await this.client.get("legal/get-policy", {
      params: { id },
    });
    return response.data;
  }

  async syncAffiliate(userId) {
    const response = await this.client.get("affiliates/sync", {
      params: { user_id: userId },
    });

    return response.data;
  }

  async submitPayoutRequest(totalAmount, commissionRecords, affiliateID) {
    const response = await this.client.post("affiliates/submit-payout", {
      totalAmount: totalAmount,
      commissionRecords: commissionRecords, // No need to stringify
      affiliateID: affiliateID,
    });

    return response.data;
  }

  async updateAffiliateData(data, companyID, userID) {
    const response = await this.client.put("affiliates/update-profile", {
      affiliateEmail: data.affiliateEmail,
      affiliateName: data.affiliateName,
      affiliatePhone: data.affiliatePhone,
      bankName: data.bankName,
      bicCode: data.bicCode,
      companyAddress: data.companyAddress,
      companyCity: data.companyCity,
      companyCountry: data.companyCountry,
      companyLegalMail: data.companyLegalMail,
      companyName: data.companyName,
      companyZipCode: data.companyZipCode,
      fiscalCode: data.fiscalCode,
      ibanNumber: data.ibanNumber,
      vatNumber: data.vatNumber,
      companyID: companyID,
      userID: userID,
    });

    return response.data;
  }
}

export default Closbox;
