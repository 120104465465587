import * as React from "react";
import UserDashboardWrapper from "../ReusableComponents/UserDashboardWrapper";
import { useUser } from "../../AuthProvider/UserContext";
import Closbox from "../../Api/Closbox";
import { parseISO, formatDistanceToNow } from "date-fns";
import { usePagination } from "@mantine/hooks";
import noRecords from "../../Images/Illustrations/no-records.svg"
import { useNavigate } from "react-router-dom";
import {
  Title,
  Loader,
  Container,
  Paper,
  Avatar,
  Group,
  Text,
  Badge,
  Table,
  Pagination,
  Grid,
  Col,
  Image,
  Card,
  Button,
} from "@mantine/core";

export default function Bookings() {
    const { userData, setUserData} = useUser();
    const [affiliationData, setAffiliationData] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(true);
    const ITEMS_PER_PAGE = 10;
    const [totalPages, setTotalPages] = React.useState(0);
    const navigate = useNavigate();
    

    React.useEffect(() => {
        // Controllo iniziale per dati nel sessionStorage
        const savedData = sessionStorage.getItem("affiliationData");
        if (savedData) {
        setAffiliationData(JSON.parse(savedData));
        }

        const fetchAffiliateData = () => {
        const closboxApi = Closbox.getInstance();
        closboxApi.setAuthToken(userData.token);

        closboxApi
            .syncAffiliate(userData.user.id)
            .then((response) => {
            const data = response.data;
            setUserData({
              user: userData.user,
              token: userData.token,
              company: data.company,
            });
            sessionStorage.setItem("affiliationData", JSON.stringify(data));
            setAffiliationData(data);
            setIsLoading(false);
            console.log(data);
            })
            .catch((error) => {
            console.error("Errore durante il caricamento dei dati:", error);

            // Se la chiamata fallisce e non abbiamo dati nel sessionStorage
            if (!savedData) {
                setIsLoading(false);
            }
            });
        };

        fetchAffiliateData();
    }, []);



    React.useEffect(() => {
        const totalRecords = affiliationData ? affiliationData.allCommissionRecords.length : 0;
        setTotalPages(Math.ceil(totalRecords / ITEMS_PER_PAGE));

    }, [affiliationData]);

    const {active: currentPage, setPage} = usePagination({
      initialPage: 1,
      total: totalPages,
      siblings: 1,
    });

    let data = null;

    if (affiliationData) {
        data = affiliationData.allCommissionRecords;
    }

    const jobColors = {
      created: "primary.9",
      confirmed: "warning.9",
      cancelled: "danger.9",
      paid:"success.9",
    };

    //Showing a loader until all the data are not fetched properly.

    if (isLoading || !data) {
        return (
        <Container
            style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            }}
        >
            <Paper padding="lg" style={{ textAlign: "center" }}>
            <Loader size="xl" color="danger.9" />
            </Paper>
        </Container>
        );
    }

    // Calculate which records to display for the current page
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const currentRecords = affiliationData
        ? affiliationData.allCommissionRecords.slice(startIndex, endIndex)
        : [];

    //Creating dynamic table rows

    const rows = currentRecords.map((record) => {
        return (
        <tr key={record.booking_id}>
            <td>
            <Group gap="sm">
                <Avatar size={30} radius={30}>
                &#128640;
                </Avatar>
                <div>
                <Text fz="md" color="primary.9" fw={500}>
                    New booking
                </Text>
                <Text fz="xs" color="dimmed">
                    Created {formatDistanceToNow(parseISO(record.created_at))} ago
                </Text>
                </div>
            </Group>
            </td>

            <td>
            <Badge
                color={jobColors[record.status.toLowerCase()]}
                variant="outline"
            >
                {record.status}
            </Badge>
            </td>
            <td>
            <Text fz="sm" color="primary.9">
                Booking total amount
            </Text>
            <Text fz="xs" color="dimmed">
                {new Intl.NumberFormat("it-IT", {
                style: "currency",
                currency: "EUR",
                }).format(record.booking_total_amount)}
            </Text>
            </td>
            <td>
            <Text fz="sm" color="primary.9">
                Your earnings
            </Text>
            <Text fz="xs" color="dimmed">
                +{" "}
                {new Intl.NumberFormat("it-IT", {
                style: "currency",
                currency: "EUR",
                }).format(record.commission_amount)}
            </Text>
            </td>
            <td>
            <Text fz="sm" color="primary.9">
                Commission percentage applied
            </Text>
            <Text fz="xs" color="dimmed">
                {record.percentage_applied} %
            </Text>
            </td>
        </tr>
        );
    });


    const toDashboard = (event)=>{
        event.preventDefault();

        navigate("/dashboard")
    }


    if (data.length === 0) {
      return (
        <UserDashboardWrapper>
          <Container fluid sx={{ height: "100%" }}>
            <Card style={{ textAlign: "center" }}>
              <Image
                maw={500}
                mx="auto"
                radius="md"
                src={noRecords}
                alt="Nessun record"
              />
              <Title size="h1" color="primary.9">
                There are no commission records to show here
              </Title>
              <Text size="md" color="dimmed">
                Start promoting your Qr Code to get new reservations and commissions.
              </Text>
              <Button color="danger.9" size="md" radius="xl" m={10} onClick={toDashboard}>
                Go back to dashboard
              </Button>
            </Card>
          </Container>
        </UserDashboardWrapper>
      );
    }

    return (
      <>
        <UserDashboardWrapper>
          <Container fluid>
            <Grid gutter="md">
              <Col span={12}>
                <Title
                  size="h1"
                  color="primary.9"
                >
                  Commission history
                </Title>
              </Col>
            </Grid>
            <div style={{ overflowX: "auto", backgroundColor:"white", marginTop:"25px", borderRadius:"15px" }}>
              <Table
                verticalSpacing="sm"
                style={{ minWidth: "800px", whiteSpace: "nowrap" }}
              >
                <tbody>{rows}</tbody>
              </Table>
            </div>

            <Grid gutter="md" justify="center">
              <Col span={12}>
                <div style={{ marginTop: "20px" }}>
                  <Pagination
                    total={totalPages}
                    onChange={setPage}
                    radius={"md"}
                    color="danger.9"
                  />
                </div>
              </Col>
            </Grid>
          </Container>
        </UserDashboardWrapper>
      </>
    );
}
