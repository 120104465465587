import {
  createStyles,
  Image,
  Container,
  Title,
  Button,
  Group,
  Text,
  List,
  ThemeIcon,
  rem,
} from "@mantine/core";
import React from "react";
import { IconCheck } from "@tabler/icons-react";
import image from "../../Images/WelcomePage.png";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";


const useStyles = createStyles((theme) => ({
  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: `calc(${theme.spacing.xl} * 4)`,
    paddingBottom: `calc(${theme.spacing.xl} * 4)`,
    flexDirection: "row", // Aggiunto per esplicitare la direzione predefinita

    [theme.fn.smallerThan("xs")]: {
      paddingTop: `calc(${theme.spacing.xs} * 4)`,
      paddingBottom: `calc(${theme.spacing.xs} * 4)`,
      flexDirection: "column", // L'immagine verrà posizionata sotto il contenuto su dispositivi più piccoli di "xs"
    },
  },

  content: {
    maxWidth: rem(480),
    marginRight: `calc(${theme.spacing.xl} * 3)`,

    [theme.fn.smallerThan("xs")]: {
      maxWidth: "100%",
      marginRight: 0,
      padding: "20px",
    },
  },

  title: {
    color: theme.colors.primary,
    fontSize: rem(55),
    lineHeight: 1.2,
    fontWeight: 900,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(36),
    },
  },

  titleColorfulSpan: {
    color: theme.colors.danger,
    fontSize: rem(55),
    lineHeight: 1.2,
    fontWeight: 900,

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(36),
    },
  },

  subtitleColorfulSpan: {
    color: theme.colors.primary,
    fontWeight: 500,
  },

  control: {
    [theme.fn.smallerThan("xs")]: {
      flex: 1,
    },
  },

  image: {
    flex: 1,
    width: "90%", // Aumenta la dimensione dell'immagine su desktop
    height: "auto", // Mantieni le proporzioni originali dell'immagine
    margin: "0 auto", // Centra l'immagine orizzontalmente su desktop
    minWidth: "400px",

    [theme.fn.smallerThan("sm")]: {
      order: "-1", // Assicurati che l'immagine venga dopo il contenuto
      width: "40%!important", // Ridimensiona l'immagine al 80% della sua larghezza originale
      height: "auto", // Mantieni le proporzioni originali dell'immagine
      margin: "20px auto", // Centra l'immagine orizzontalmente e aggiunge uno spazio sopra e sotto
      minWidth: "350px",
    },
  },

  listItem: {
    color: theme.colors.primary[9],
  },

  highlight: {
    position: "relative",
    backgroundColor: theme.fn.variant({
      variant: "light",
      color: theme.primaryColor,
    }).background,
    borderRadius: theme.radius.sm,
    padding: `${rem(4)} ${rem(12)}`,
  },
}));

export default function HeroBanner() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Funzione per scorrere verso la sezione "program"
  const scrollToProgram = () => {
    const section = document.getElementById("programs");
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
      <Container size="lg" mt={35}>
        <div className={classes.inner}>
          <div className={classes.content}>
            <Title className={classes.title}>
              {t("homepage.heroBanner.title-1")}
              <span className={`${classes.titleColorfulSpan}`}>
                {" "}
                {t("homepage.heroBanner.title-2")}
              </span>
            </Title>
            <Text color="dimmed" mt="lg" fz="lg">
              {t("homepage.heroBanner.subtitle")}
            </Text>
            <List
              mt={30}
              spacing="sm"
              size="sm"
              icon={
                <ThemeIcon size={20} radius="xl" variant="light" color="danger">
                  <IconCheck size={rem(18)} stroke={2.5} />
                </ThemeIcon>
              }
            >
              {/* Lista degli elementi */}
            </List>
            <Group mt={30}>
              <Button
                radius="xl"
                size="md"
                className={classes.control}
                color="danger.0"
                onClick={() => {
                  navigate("/signup");
                }}
              >
                {t("homepage.heroBanner.btn-1")}
              </Button>
              <Button
                variant="default"
                radius="xl"
                size="md"
                className={classes.control}
                color="primary"
                onClick={scrollToProgram} // Scorrimento fluido
              >
                {t("homepage.heroBanner.btn-2")}
              </Button>
            </Group>
          </div>
          <Image src={image} className={classes.image} />
        </div>
      </Container>
    </div>
  );
}