import * as React from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

import { useDisclosure } from "@mantine/hooks";

import { Modal, Button, Group, SimpleGrid } from "@mantine/core";
import { IconShare } from "@tabler/icons-react";

export default function ShareSocialModal(link, qrCode) {
  const [opened, { open, close }] = useDisclosure(false);

  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        title="Share it with the people you love"
        centered
        color="primary.9"
        radius={"lg"}
      >
        <SimpleGrid cols={1}>
          <WhatsappShareButton
            url={link}
            title="Drop your bags at Closbox and enjoy the city without heavy luggages. Smartest, safest and cheapr luggage storage you'll find"
          >
            <Button
              radius="lg"
              variant="outline"
              padding="md"
              color="primary.9"
            >
              Share on whatsapp
              <WhatsappIcon
                size={20}
                round={true}
                style={{ marginLeft: "10px" }}
              />
            </Button>
          </WhatsappShareButton>
          <FacebookShareButton
            url={link}
            quote="Drop your bags at Closbox and enjoy the city without heavy luggages. Smartest, safest and cheapr luggage storage you'll find"
            hashtag={"#ClosboxLuggageStorage"}
          >
            <Button
              radius="lg"
              variant="outline"
              padding="md"
              color="primary.9"
            >
              Share on Facebook
              <FacebookIcon
                size={20}
                round={true}
                style={{ marginLeft: "10px" }}
              />
            </Button>
          </FacebookShareButton>
          <LinkedinShareButton
            url={link}
            title="Closbox Luggage Storage"
            summary="Drop your bags at Closbox and enjoy the city without heavy luggages. Smartest, safest and cheapr luggage storage you'll find"
            source="Closbox.com"
          >
            <Button
              radius="lg"
              variant="outline"
              padding="md"
              color="primary.9"
            >
              Share on LinkedIn
              <LinkedinIcon
                size={20}
                round={true}
                style={{ marginLeft: "10px" }}
              />
            </Button>
          </LinkedinShareButton>
          <TwitterShareButton
            url={link}
            title="Closbox Luggage Storage"
            via="Closbox.com"
          >
            <Button
              radius="lg"
              variant="outline"
              padding="md"
              color="primary.9"
            >
              Share on Twitter
              <TwitterIcon
                size={20}
                round={true}
                style={{ marginLeft: "10px" }}
              />
            </Button>
          </TwitterShareButton>
        </SimpleGrid>
      </Modal>

      <Group position="right">
        <Button onClick={open} radius="lg" color="primary.9" rightIcon={<IconShare/>}>Share</Button>
      </Group>
    </>
  );
}
