import * as React from "react";
import {Paper, Title, Text, Image}from "@mantine/core"
import { useTranslation } from "react-i18next";

export default function QrCodeCard({qrCodeLink}){

    console.log(qrCodeLink)

    const {t} = useTranslation();


    return (
      <Paper shadow="lg" radius="lg" p="lg">
        <Title size="h3" color="primary.9">
          {t("dashboard.qr.title")}
        </Title>
        <Text color="dimmed" fz="sm" mt={5}>
          {t("dashboard.qr.description")}
        </Text>

        <Image
          maw={240}
          mx="auto"
          radius="md"
          src={qrCodeLink}
          alt="QR code Image"
        />
      </Paper>
    );
    
}