import { useDisclosure } from "@mantine/hooks";
import {
  Modal,
  Group,
  Button,
  Title,
  TextInput,
  Text,
  List,
  ThemeIcon,
} from "@mantine/core";
import * as React from "react";
import { IconSend, IconCheck } from "@tabler/icons-react";
import Closbox from "../../Api/Closbox";
import { notifications } from "@mantine/notifications";



export default function PayoutRequestModal({data, onSuccessfulPayout}) {
    const [opened, { open, close }] = useDisclosure(false);
    const [step, setStep] = React.useState(0)
    const [isLoading, setIsLoading] = React.useState(false);
    let unpaidRecords = [];
    let totalAmountDue = 0;


    /* The code is iterating over each element in the `data.allCommissionRecords` array and checking if
    the `status` property of each record is equal to "confirmed". If it is, the record is added to the
    `unpaidRecords` array. This code is filtering out only the records with a status of "confirmed"
    from the `data.allCommissionRecords` array. */
    data.allCommissionRecords.map((record)=>{
        if(record.status === "confirmed"){
            totalAmountDue += record.commission_amount
            unpaidRecords.push(record);
        }
    })


    const nextStep = () => {
        setStep((prevStep) => prevStep + 1); // <--- Increment the step
    };

    const submitPayoutRequest = (event) =>{
        event.preventDefault();
        setIsLoading(true)

        const closboxApi = Closbox.getInstance();

        closboxApi.submitPayoutRequest(totalAmountDue, unpaidRecords, data.affiliateID).then((response)=>{
            if(response.message === "Ok"){
                onSuccessfulPayout();
                close();
                notifications.show({
                  title: "Payout request created!",
                  message: "Your payout request was submitted, you'll be notified once the payment has been issued",
                  color:"success.9",
                  icon: <IconCheck/>,
                });
                setIsLoading(false);
            }
        })


    }
  

  return (
    <>
      <Modal
        size="lg"
        opened={opened}
        onClose={close}
        centered
        withCloseButton={false}
        radius={"lg"}
      >
        <div>
          <Title color="dimmed" size="h3">
            Tell us your invoice number
          </Title>
          <Text color="primary.9">
            Please insert below the invoice number you issued for this payout
            request
          </Text>
          <form>
            <TextInput
              label="Invoice number"
              placeholder="01/2056"
              radius="md"
              color="primary"
            />
          </form>
          <Group position="right" mt={25}>
            <Button
              radius="xl"
              color="primary.9"
              rightIcon={<IconSend size={20} />}
              onClick={submitPayoutRequest}
              loading={isLoading}
            >
              Submit payout request
            </Button>
          </Group>
        </div>
      </Modal>

      <Group position="right" mt={15}>
        <Button radius="xl" color="primary.9" onClick={open}>
          New payout request
        </Button>
      </Group>
    </>
  );
}
