import {
  createStyles,
  Badge,
  Group,
  Title,
  Text,
  Card,
  Container,
  rem,
  Grid,
  Divider,
  ThemeIcon,
  List,
  Button,
} from "@mantine/core";

import {
  IconQrcode,
  IconHeartHandshake,
  IconCheck,
} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";


const useStyles = createStyles((theme) => ({
  container: {
    marginTop: "45px!important",
  },

  badge: {
    color: theme.colors.primary[9],
  },

  title: {
    color: theme.colors.primary,
    fontSize: rem(45),
    lineHeight: 1.2,
    fontWeight: 900,
    marginTop: "25px!important",

    [theme.fn.smallerThan("xs")]: {
      fontSize: rem(32),
      textAlign: "center",
    },
  },

  listItem: {
    color: theme.colors.primary[9],
  },
}));


export default function Programs(){

    const { classes, theme } = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();


    return (
      <>
        <Divider />
        <Container size="lg" className={classes.container} id="programs">
          <Grid gutterXs="md" gutterMd="xl">
            <Grid.Col
              lg={5}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Badge
                variant="filled"
                size="lg"
                color="warning.9"
                className={classes.badge}
                mb={25}
              >
                {t("homepage.programs.badge")}
              </Badge>
              <Title
                order={1}
                size="h1"
                color="primary.9"
                className={classes.title}
              >
                {t("homepage.programs.title")}
              </Title>
            </Grid.Col>
            <Grid.Col lg={7} xs={12}>
              <Group>
                <Card
                  key="1"
                  shadow="md"
                  radius="lg"
                  className={classes.card}
                  padding="xl"
                >
                  <IconQrcode
                    size={rem(50)}
                    stroke={2}
                    color={theme.colors.danger[9]}
                  />
                  <Text
                    fz="2xl"
                    fw={700}
                    className={classes.cardTitle}
                    mt="md"
                    color="gray.6"
                  >
                    Silver program
                  </Text>
                  <Text
                    fz="xl"
                    fw={500}
                    className={classes.cardTitle}
                    mt="md"
                    color="primary.9"
                  >
                    {t("homepage.programs.silver.title")}
                  </Text>
                  <List
                    mt={30}
                    spacing="sm"
                    size="sm"
                    icon={
                      <ThemeIcon
                        size={20}
                        radius="xl"
                        variant="light"
                        color="danger"
                      >
                        <IconCheck size={rem(18)} stroke={2.5} />
                      </ThemeIcon>
                    }
                  >
                    <List.Item className={classes.listItem}>
                      <b>{t("homepage.programs.silver.hint-1")}</b>
                    </List.Item>
                    <List.Item className={classes.listItem}>
                      <b>{t("homepage.programs.silver.hint-2")}</b>
                    </List.Item>
                    <List.Item className={classes.listItem}>
                      <b>{t("homepage.programs.silver.hint-3")}</b>
                    </List.Item>
                  </List>
                  <Text fz="sm" c="dimmed" mt="sm">
                    {t("homepage.programs.silver.description")}
                  </Text>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      radius="xl"
                      size="md"
                      className={classes.control}
                      color="danger.0"
                      onClick={() => {
                        navigate("/signup");
                      }}
                    >
                      {t("homepage.heroBanner.btn-1")}
                    </Button>
                  </div>
                </Card>
                <Card
                  key="1"
                  shadow="md"
                  radius="lg"
                  className={classes.card}
                  padding="xl"
                >
                  <IconHeartHandshake
                    size={rem(50)}
                    stroke={2}
                    color={theme.colors.danger[9]}
                  />
                  <Text
                    fz="2xl"
                    fw={700}
                    className={classes.cardTitle}
                    mt="md"
                    color="yellow.9"
                  >
                    Gold program
                  </Text>
                  <Text
                    fz="lg"
                    fw={500}
                    className={classes.cardTitle}
                    mt="md"
                    color="primary.9"
                  >
                    {t("homepage.programs.gold.title")}
                  </Text>
                  <List
                    mt={30}
                    spacing="sm"
                    size="sm"
                    icon={
                      <ThemeIcon
                        size={20}
                        radius="xl"
                        variant="light"
                        color="danger"
                      >
                        <IconCheck size={rem(18)} stroke={2.5} />
                      </ThemeIcon>
                    }
                  >
                    <List.Item className={classes.listItem}>
                      <b>{t("homepage.programs.gold.hint-1")}</b>
                    </List.Item>
                    <List.Item className={classes.listItem}>
                      <b>{t("homepage.programs.gold.hint-2")}</b>
                    </List.Item>
                    <List.Item className={classes.listItem}>
                      <b>{t("homepage.programs.gold.hint-3")}</b>
                    </List.Item>
                  </List>
                  <Text fz="sm" c="dimmed" mt="sm">
                    {t("homepage.programs.gold.description")}
                  </Text>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: "20px",
                    }}
                  >
                    <Button
                      variant="default"
                      radius="xl"
                      size="md"
                      className={classes.control}
                      color="primary"
                      onClick={()=>navigate("#contacts")}
                    >
                      {t("homepage.programs.gold.btn")}
                    </Button>
                  </div>
                </Card>
              </Group>
            </Grid.Col>
          </Grid>
        </Container>
      </>
    );
}
