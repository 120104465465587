import React from "react";
import {
  createStyles,
  Group,
  Paper,
  SimpleGrid,
  Text,
  rem,
} from "@mantine/core";
import {
  IconQrcode,
  IconCoinEuro,
  IconUserDollar,
  IconCoin,
  IconArrowUpRight,
  IconArrowDownRight,
} from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: "25px",
    paddingBottom:"25px"
  },

  value: {
    fontSize: rem(24),
    fontWeight: 700,
    lineHeight: 1,
  },

  diff: {
    lineHeight: 1,
    display: "flex",
    alignItems: "center",
  },

  icon: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  title: {
    fontWeight: 700,
    textTransform: "uppercase",
  },
}));

const icons = {
  qr: IconQrcode,
  earnings: IconCoinEuro,
  booking: IconUserDollar,
  coin: IconCoin,
};

function StatsGrid(props) {
  const { classes } = useStyles();
  const stats = props.data.map((stat) => {
    const Icon = icons[stat.icon];
    const DiffIcon = stat.diff > 0 ? IconArrowUpRight : IconArrowDownRight;

    return (
      <Paper p="md" radius="lg" key={stat.title} shadow="sm">
        <Group position="apart">
          <Text size="xs" color="dimmed" className={classes.title}>
            {stat.title}
          </Text>
          <Icon className={classes.icon} size="1.4rem" stroke={1.5} />
        </Group>

        <Group align="flex-end" spacing="xs" mt={25}>
          <Text className={classes.value} color="primary.9">{stat.value}</Text>
          <Text
            color={stat.diff > 0 ? "teal" : (stat.diff === "" ? "primary":"red")}
            fz="sm"
            fw={500}
            className={classes.diff}
          >
            {stat.diff !== "" && (
              <>
              <span>{stat.diff}%</span>
              <DiffIcon size="1rem" stroke={1.5} />
              </>
            )}
          </Text>
        </Group>

        <Text fz="xs" c="dimmed" mt={7}>
          Compared to previous month
        </Text>
      </Paper>
    );
  });

  return (
    <div className={classes.root}>
      <SimpleGrid
        cols={3}
        breakpoints={[
          { maxWidth: "md", cols: 2 },
          { maxWidth: "xs", cols: 1 },
        ]}
      >
        {stats}
      </SimpleGrid>
    </div>
  );
}

export default StatsGrid;
