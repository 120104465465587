import {
  createStyles,
  Header,
  Text,
  Container,
  Group,
  Button,
  rem,
} from "@mantine/core";
import ClosboxLogo from "./ClosboxLogo";
import { IconUser, IconArrowsRightLeft } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import {useUser} from "../../AuthProvider/UserContext";
import { LanguageSwitcher } from "../../Localization";

const HEADER_HEIGHT = rem(60);

const useStyles = createStyles((theme) => ({
  inner: {
    height: HEADER_HEIGHT,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  header:{
    position:"fixed",
  }
}));

export default function Navbar() {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const { isAuthenticated } = useUser();

  const toLoginPage = (event) => {
    event.preventDefault();
    navigate("/login");
  };

  return (
    <Header height={HEADER_HEIGHT} className={classes.header} mb={0}>
      <Container className={classes.inner} fluid size="lg">
        <Group onClick={() => navigate("/dashboard")}>
          <ClosboxLogo />
        </Group>
        <Group>

        <LanguageSwitcher />
          {!isAuthenticated ? (
            <Button
              radius="xl"
              color="primary.9"
              onClick={toLoginPage}
            >
              Log In
            </Button>
          ) : (
            <Button
              rightIcon={<IconArrowsRightLeft size={"1.2rem"} />}
              radius="xl"
              color="danger.9.9"
              variant="outline"
              onClick={() => navigate("/dashboard")}
            >
              Go to dashboard
            </Button>
          )}
        </Group>
        {/* <Button
          
        >
          <Text>Login</Text>
        </Button> */}
      </Container>
    </Header>
  );
}
