import * as React from "react";
import { Paper, Title, Text, Image, CopyButton, Tooltip, ActionIcon, Flex } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { IconCheck, IconCopy } from "@tabler/icons-react";

export default function CouponCard({ couponCode }) {


    const {t} = useTranslation();

  return (
    <Paper shadow="lg" radius="lg" p="lg">
      <Title size="h3" color="primary.9">
        {t("dashboard.coupon.title")}
      </Title>
      <Text color="dimmed" fz="sm" mt={5}>
        {t("dashboard.coupon.description")}
      </Text>

      <Flex
        mih={50}
        bg="gray.1"
        gap="md"
        sx={{ borderRadius: "20px" }}
        justify="center"
        align="center"
        direction="row"
        wrap="wrap"
        my={20}
      >
        <Text color="dimmed">{couponCode}</Text>
        <CopyButton value={couponCode} timeout={2000}>
          {({ copied, copy }) => (
            <Tooltip
              label={copied ? "Copied" : "Copy"}
              withArrow
              position="right"
            >
              <ActionIcon color={copied ? "teal" : "gray"} onClick={copy}>
                {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
              </ActionIcon>
            </Tooltip>
          )}
        </CopyButton>
      </Flex>
    </Paper>
  );
}
