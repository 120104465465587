import {
  Container,
  Grid,
  SimpleGrid,
  useMantineTheme,
  rem,
  Image,
  Title,
  createStyles,
  Group,
  Badge,
} from "@mantine/core";

import image1 from "../../Images/Shop1.jpg"
import image2 from "../../Images/Shop_M.webp";
import image3 from "../../Images/Shop_L.jpg";
import image4 from "../../Images/Shop_XL.webp";
import { useTranslation } from "react-i18next";

const useStyles = createStyles((theme) => ({
  container: {
    marginTop: "45px",
    marginBottom : "45px",
  },

  badge: {
    color: theme.colors.primary[9],
  },

  title: {
    marginBottom:"50px!important",
    fontSize:rem(45),
  },
}));


const PRIMARY_COL_HEIGHT = rem(300);

export default function PicturesGrid() {
  const theme = useMantineTheme();
    const { t } = useTranslation();

  const {classes} = useStyles();

  const SECONDARY_COL_HEIGHT = `calc(${PRIMARY_COL_HEIGHT} / 2 - ${theme.spacing.md} / 2)`;

  return (
    <Container size="lg" className={classes.container}>
      <Container>
        <Group position="center" mb={12}>
          <Badge
            variant="filled"
            size="lg"
            color="success.9"
            className={classes.badge}
          >
            {t("homepage.shopGrid.badge")}
          </Badge>
        </Group>
        <Title
          align="center"
          size="h1"
          color="primary.9"
          className={classes.title}
        >
          {t("homepage.shopGrid.title")}
        </Title>
      </Container>
      <SimpleGrid
        cols={2}
        spacing="md"
        breakpoints={[{ maxWidth: "sm", cols: 1 }]}
      >
        <Image
          height={PRIMARY_COL_HEIGHT}
          radius="md"
          animate={false}
          src={image3}
        />
        <Grid gutter="md">
          <Grid.Col>
            <Image
              height={SECONDARY_COL_HEIGHT}
              radius="md"
              animate={false}
              src={image1}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Image
              height={SECONDARY_COL_HEIGHT}
              radius="md"
              animate={false}
              src={image2}
            />
          </Grid.Col>
          <Grid.Col span={6}>
            <Image
              height={SECONDARY_COL_HEIGHT}
              radius="md"
              animate={false}
              src={image4}
            />
          </Grid.Col>
        </Grid>
      </SimpleGrid>
    </Container>
  );
}
