import axios from "axios";

class ClosboxV2 {
  constructor() {
    const token = process.env.REACT_APP_CLOSBOX_TOKEN;
    this.client = axios.create({
      baseURL: process.env.REACT_APP_CLOSBOX_URL_V2,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
  }

  static instance;
  static getInstance() {
    if (!ClosboxV2.instance) {
      ClosboxV2.instance = new ClosboxV2();
    }
    return ClosboxV2.instance;
  }

  setAuthToken(token) {
    if (token) {
      this.client.defaults.headers["Authorization"] = `Bearer ${token}`;
    } else {
      delete this.client.defaults.headers["Authorization"];
    }
  }

  async validateCoupon(coupon, pointOfSaleID) {
    const res = await this.client.post("/coupons/validate", {
      code: coupon,
      pointOfSaleID: pointOfSaleID,
    });

    return res.data;
  }

  async checkAvailability(beginningDateTime, duration, pointOfSaleID) {
    const response = await this.client.post("/bookings/check-availability", {
      beginningDateTime: beginningDateTime,
      duration: duration,
      pointOfSaleID: pointOfSaleID,
    });

    return response.data;
  }

  async createBookingIntent(
    beginningDateTime,
    endDateTime,
    duration,
    pointOfSaleID,
    affiliateToken,
    locale,
    lockers,
    addons,
    paymentProvider
  ) {
    const response = await this.client.post("/bookings/create-booking-intent", {
      beginningDateTime: beginningDateTime,
      endDateTime: endDateTime,
      duration: duration,
      pointOfSaleID: pointOfSaleID,
      affiliateToken: affiliateToken,
      locale: locale,
      lockers: lockers,
      addons: addons,
      paymentProvider: paymentProvider,
    });

    return response.data;
  }

  async confirmBookingIntent(
    bookingNumber,
    recoveryWord,
    customerID = null,
    userData = null,
    discountCode = null,
    termsAndConditions
  ) {
    const body = {
      bookingNumber: bookingNumber,
      recoveryWord: recoveryWord,
      userID: customerID,
      userData: userData,
      discountCode: discountCode,
      termsAndConditions: termsAndConditions,
    };

    const response = await this.client.patch(
      "/bookings/confirm-booking-intent",
      body
    );


    return response.data;
  }

  async fetchBookingIntentStatus(bookingNumber) {
    const res = await this.client.get(
      `/bookings/fetch-booking-intent-status?bookingNumber=${encodeURIComponent(
        bookingNumber
      )}`
    );

    return res.data;
  }

  async keepAlive(bookingNumber) {
    const body = {
      bookingNumber: bookingNumber,
    };

    const response = await this.client.post(
      "/bookings/keep-alive-intent",
      body
    );

    return response.data;
  }

  async postReview(bookingNumber, rating, reviewBody) {
    const body = {
      bookingNumber: bookingNumber,
      rating: rating,
      reviewBody: reviewBody,
    };

    const response = await this.client.post("/reviews/post-review", body);

    return response.data;
  }

  async getUserBookings(userID) {
    const res = await this.client.get(`/bookings/user/${userID}`);

    return res.data;
  }

  async searchBooking(bookingNumber, pinOrRecoveryWord) {
    const body = {
      bookingNumber: bookingNumber,
      pinOrRecoveryWord: pinOrRecoveryWord,
    };

    const response = await this.client.post("/bookings/find-booking", body);

    return response.data;
  }

  async deleteBooking(bookingNumber) {
    const body = {
      bookingNumber: bookingNumber,
    };

    const response = await this.client.put("/bookings/delete-booking", body);

    return response.data;
  }

  async register(fullName, phoneNumber, email, password, languages) {
    const body = {
      fullName: fullName,
      phoneNumber: phoneNumber,
      email: email,
      password: password,
      role: 10,
      languages: languages,
    };

    const res = await this.client.post("/auth/register", body);

    return res.data;
  }

  async login(email, password) {
    const body = {
      email: email,
      password: password,
      source: "web",
    };

    const res = await this.client.post("/auth/login", body);

    return res.data;
  }

  async authWithGoogle(token) {
    const body = {
      token: token,
    };

    const res = await this.client.post("/auth/google/auth", body);

    return res.data;
  }

  async checkOut(bookingNumber, pinOrRecoveryWord, pointOfSaleID) {
    const response = await this.client.post("/rent/check-out", {
      bookingNumber: bookingNumber,
      pinOrRecoveryWord: pinOrRecoveryWord,
      pointOfSaleID: pointOfSaleID,
    });

    return response.data;
  }

  async unlockDoors(bookingNumber, pinOrRecoveryWord, pointOfSaleID) {
    const response = await this.client.post("/rent/unlock", {
      bookingNumber: bookingNumber,
      pinOrRecoveryWord: pinOrRecoveryWord,
      pointOfSaleID: pointOfSaleID,
    });

    return response.data;
  }

  async checkIn(bookingNumber, pinOrRecoveryWord, pointOfSaleID) {
    const response = await this.client.post("/rent/check-in", {
      bookingNumber: bookingNumber,
      pinOrRecoveryWord: pinOrRecoveryWord,
      pointOfSaleID: pointOfSaleID,
    });

    return response.data;
  }

  async earlyCheckIn(bookingNumber, pinOrRecoveryWord, pointOfSaleID) {
    const response = await this.client.post("/rent/early-check-in", {
      bookingNumber: bookingNumber,
      pinOrRecoveryWord: pinOrRecoveryWord,
      pointOfSaleID: pointOfSaleID,
    });

    return response.data;
  }

  async createTicket(
    name,
    surname,
    email,
    phone,
    booking_number,
    category,
    subject,
    message,
    locale

  ) {

    const source = "affiliates.closbox.com";

    const response = await this.client.post("crm/create-ticket", {
      name:name,
      surname:surname,
      email:email,
      phone:phone,
      booking_number:booking_number,
      category:category,
      subject:subject,
      message:message,
      source:source,
      locale:locale,
    });

    return response.data;
  }
}

export default ClosboxV2;
