import {
  TextInput,
  PasswordInput,
  Title,
  Text,
  Button,
  Anchor,
  Alert,
  rem,
  Flex,
  Box,
  Grid,
} from "@mantine/core";
import Closbox from "../../Api/Closbox";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../AuthProvider/UserContext";
import * as React from "react";
import { IconExclamationCircle} from "@tabler/icons-react";
import { useMantineTheme } from "@mantine/core";
import Navbar from "../ReusableComponents/Navbar";


export default function LoginForm() {
  const [isLoading, setIsLoading] = React.useState(false);
  const { setUserData } = useUser();
  const [error, setError] = React.useState(null);
  const navigate = useNavigate();
  const theme = useMantineTheme();

  function login(event) {
    setError(null);
    setIsLoading(true);
    event.preventDefault();

    const formData = new FormData(event.currentTarget);
    const loginForm = Object.fromEntries(formData.entries());

    const closboxApi = Closbox.getInstance();

    closboxApi
      .login(loginForm.email, loginForm.password, "affiliates")
      .then((data) => {
        if (data.token != null && data.user != null) {
          closboxApi.setAuthToken(data.token);
          setUserData({ user: data.user, token: data.token});
          navigate("/dashboard");
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.message)
        setError("Invalid Credentials. Try Again");
        setIsLoading(false);
      });
  }

  const toSignupPage = (event)=>{
    event.preventDefault();
    navigate('/signup');
  };
  
  return (
    <>
      <Navbar />
      <Box
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "25px",
        }}
      >
        <Title align="center" size={rem(45)} color="primary.9">
          Login
        </Title>
        <Text color="dimmed" size="lg" align="center" mt={5}>
          or{" "}
          <Anchor size="lg" component="button" onClick={toSignupPage}>
            {" "}
            Sign-up
          </Anchor>
        </Text>
        <Grid style={{ width: "100%" }} justify={"center"}>
          <Grid.Col sm={6} lg={3}>
            <form onSubmit={login} style={{ width: "100%" }}>
              <TextInput
                label="Email"
                placeholder="you@closbox.com"
                name="email"
                radius="md"
                required
                size="lg"
                error={error ? true : false}
                style={{ width: "100%!important" }}
              />
              <PasswordInput
                label="Password"
                placeholder="Your password"
                name="password"
                required
                mt="md"
                radius="md"
                size="lg"
                error={error ? true : false}
                style={{ width: "100%" }}
              />

              {error && (
                <Alert color="red" mt="md">
                  <Flex gap="md">
                    <IconExclamationCircle
                      size={rem(25)}
                      stroke={2}
                      color={theme.colors.danger[9]}
                    />
                    <Text color="danger.9">{error}</Text>
                  </Flex>
                </Alert>
              )}

              <Button
                fullWidth
                mt="xl"
                loading={isLoading}
                type="submit"
                color="primary.9"
                radius="xl"
                size="lg"
              >
                Log-in into your account
              </Button>
            </form>
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
}
