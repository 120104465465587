import {
  TextInput,
  Checkbox,
  Title,
  Text,
  Button,
  Select,
  Alert,
  rem,
  Flex,
  Container,
  MultiSelect,
  Box,
  Grid,
} from "@mantine/core";
import Closbox from "../../Api/Closbox";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../AuthProvider/UserContext";
import * as React from "react";
import { IconChevronRight, IconChevronLeft, IconExclamationCircle } from "@tabler/icons-react";
import { useMantineTheme } from "@mantine/core";
import PasswordWithMeter from "../ReusableComponents/PasswordInputWithMeter";
import Navbar from "../ReusableComponents/Navbar";
import { useTranslation } from "react-i18next";

export default function RegisterForm() {
    const [isLoading, setIsLoading] = React.useState(false);
    const { setUserData } = useUser();
    const [errors, setErrors] = React.useState({});
    const navigate = useNavigate();
    const theme = useMantineTheme();
    const [formValues, setFormValues] = React.useState({
      privacyPolicy: false,
      termsAndConditions: false,
    });
    const [step, setStep] = React.useState(1);
    const [languages, setLanguages] = React.useState([]);
    const [error, setError] = React.useState();
    const [companyCoreBusiness, setCompanyCoreBusiness]=React.useState(null);
    const {t} = useTranslation();

    const availableLanguages = [
      { value: "it", label: "Italian" },
      { value: "en", label: "English" },
      { value: "es", label: "Espanol" },
      { value: "fr", label: "Francais" },
      { value: "cn", label: "Chinese" },
      { value: "ru", label: "Russian" },
    ];

    const companyType = [
      { value: "bar_pub", label: "Bar or Pub" },
      { value: "restaurant_cafe", label: "Restaurant or Cafe" },
      {
        value: "accommodation_services",
        label: "Accommodation Services (Hotel/B&B/Motel/Vacation Rentals)",
      },
      {
        value: "travel_services",
        label: "Travel Services (Agency, Tour Operator)",
      },
      {
        value: "digital_influencers",
        label:
          "Digital Influencers (Bloggers, Vloggers, Social Media Personalities)",
      },
      { value: "tour_guide", label: "Tour Guide Services" },
      {
        value: "outdoor_adventures",
        label: "Outdoor Adventures and Recreations",
      },
      { value: "transport_rental", label: "Transport Rental Services" },
      { value: "event_planning", label: "Event Planning and Organizing" },
      {
        value: "educational_services",
        label: "Educational or Workshop Services",
      },
      { value: "visitor_center", label: "Visitor or Information Center" },
      { value: "sports_facility", label: "Sports Facility" },
      { value: "shopping_center", label: "Shopping Center" },
      { value: "service_apartment", label: "Service Apartment" },
      { value: "conference_center", label: "Conference or Convention Center" },
      { value: "Others", label: "Other activities type" },
    ];

    const handleInputChange = (e) => {
      if (e.target.type === "checkbox") {
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          [e.target.name]: e.target.checked,
        }));
      } else {
        setFormValues((prevFormValues) => ({
          ...prevFormValues,
          [e.target.name]: e.target.value,
        }));
      }

    };

    const handlePasswordChange = (passwordValue) => {
      setFormValues({
        ...formValues,
        password: passwordValue,
      });
    };

    function validateForm(){

        let localErrors = {};
        if (step === 1) {
          if (!formValues.name) localErrors.name = "The name is required";
          if (!formValues.email) localErrors.email = "The email is required";
          if (!formValues.password) localErrors.password = "The password is required";
          if (!formValues.phoneNumber)localErrors.phoneNumber = "The phone number is required";
          if (languages.length === 0)localErrors.languages = "Choose at least one language";
        } else {
          if (companyCoreBusiness.length === 0)localErrors.companyCoreBusiness = "The core business is required";
          if (!formValues.companyName)localErrors.companyName = "The company name is required";
          if (!formValues.companyAddress)localErrors.companyAddress = "The company address is required";
          if (!formValues.zipCode)localErrors.zipCode = "The zip code is required";
          if (!formValues.city)localErrors.city = "The city is required";
          if (!formValues.country)localErrors.country = "The country is required";
          if (!formValues.vat)localErrors.vat = "The vat number is required";
          if (!formValues.legalMail)localErrors.legalMail = "The legal mail is required";
          if (!formValues.privacyPolicy) {
            localErrors.privacyPolicy = "Please accept the Privacy Policy.";
          }
          if (!formValues.termsAndConditions) {
            localErrors.termsAndConditions =
              "Please accept the Terms and Conditions.";
          }
        }
        return localErrors;



    }

    const previousStep = (e)=>{
        e.preventDefault();
        setStep(1);
    }


    const handleLanguages = (languages) =>{
      setLanguages(languages);
    }


    const handleClick = (event)=>{
        event.preventDefault();
        setIsLoading(true);
        const localErrors = validateForm();

        if (Object.keys(localErrors).length === 0) {
          if (step === 1) {

            setStep(2);
            setErrors({})
            setIsLoading(false);

          } else if (step===2) {

            const closboxApi = Closbox.getInstance();

            let submittedData = {
              name: formValues.name,
              phoneNumber: formValues.phoneNumber,
              email: formValues.email,
              password: formValues.password,
              role: 4,
              languages: languages,
              companyName: formValues.companyName,
              companyAddress: formValues.companyAddress,
              city: formValues.city,
              zipCode: formValues.zipCode,
              country: formValues.country,
              vat: formValues.vat,
              fiscalCode: formValues.fiscalCode,
              legalMail: formValues.legalMail,
              companyEmail: formValues.companyEmail,
              type:companyCoreBusiness,
              termsAndConditions:formValues.termsAndConditions,
              privacyPolicy:formValues.privacyPolicy,
            };

            console.log(submittedData)

            closboxApi.register(submittedData)
            .then((data) => {
              if (
                data.statusCode === 200 &&
                data.message === "Registration successfull"
              ) {
                //User is successfully registered, we login him directly into the dashboard
                closboxApi
                .login(formValues.email, formValues.password, "affiliates")
                .then((data) => {
                  if (data.token != null && data.user != null) {
                    closboxApi.setAuthToken(data.token);
                    setUserData({ user: data.user, token: data.token, });
                    navigate("/dashboard");
                  }else if (
                    data.statusCode === 400 &&
                    data.message === "User already exists"
                  ){
                    setError("This user is already registered. Please log in.");
                  }
                    setIsLoading(false);
                })
                .catch((error) => {
                  setError("An error has occurred. Please try again later.");
                  setIsLoading(false);
                });
              }
            })
            .catch((error) => {
              setError("An error has occurred. Please try again later.");
              setIsLoading(false);
            });

          }
        } else {

          setErrors(localErrors);
          setIsLoading(false);
        }
    }

    const handleCompanyType= (type)=>{
      setCompanyCoreBusiness(type);
    }



  return (
    <>
      <Navbar />

      <Box
        style={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "25px",
        }}
      >
        <Grid style={{ width: "100%" }} justify={"center"}>
          <Grid.Col sm={6} lg={4}>
            <div style={{ marginTop: "55px" }}>
              {step === 1 ? (
                <>
                  <Title color="primary.9" size={rem(40)} width={"60%"}>
                    Join Us and Elevate Your Earnings! 🚀
                  </Title>
                </>
              ) : (
                <>
                  <Title color="primary.9" size={rem(40)}>
                    We're Thrilled to Partner with You!
                  </Title>
                </>
              )}
            </div>
            <form>
              {step === 1 ? (
                <>
                  <Text color="dimmed" size="md" mb={5} mt={20}>
                    Creating your company's login credentials is your first step
                    towards a profitable partnership. Complete your registration
                    below, and you'll be all set to log in and start your
                    journey with us!
                  </Text>
                  <TextInput
                    size="lg"
                    label="Full Name"
                    placeholder="John Doe"
                    name="name"
                    radius="md"
                    required
                    my="md"
                    color="primary"
                    onChange={handleInputChange}
                    error={errors.name}
                    //   error={error ? true : false}
                  />
                  <TextInput
                    size="lg"
                    label="Phone Number"
                    placeholder="Phone number"
                    required
                    type="tel"
                    my="md"
                    description="To proceed, please include your country code. For example: +39 123 455 6589"
                    radius="md"
                    onChange={handleInputChange}
                    // pattern and inputMode can be adjusted based on the specific format you want
                    pattern="^\+39 ?\d{3} ?\d{3} ?\d{4}$"
                    inputMode="tel"
                    color="primary"
                    name="phoneNumber"
                    error={errors.phoneNumber}
                  />
                  <MultiSelect
                    data={availableLanguages}
                    size="lg"
                    label="Preferred languages"
                    placeholder="Pick your preferred languages"
                    value={languages}
                    onChange={handleLanguages}
                    radius="md"
                    required
                    error={errors.languages}
                  />
                  <TextInput
                    size="lg"
                    label="Email"
                    color="primary"
                    placeholder="you@login.com"
                    name="email"
                    radius="md"
                    type="email"
                    description=""
                    onChange={handleInputChange}
                    my="md"
                    required
                    error={errors.email}
                    //   error={error ? true : false}
                  />
                  <PasswordWithMeter
                    value={formValues.password}
                    onChange={(passwordValue) =>
                      handlePasswordChange(passwordValue)
                    }
                  />

                  {Object.keys(errors).length > 0 && (
                    <Alert color="red" mt="md">
                      <Flex gap="md">
                        <IconExclamationCircle
                          size={rem(25)}
                          stroke={2}
                          color={theme.colors.danger[9]}
                        />
                        <Text color="danger.9">
                          Please fix the above errors and try again
                        </Text>
                      </Flex>
                    </Alert>
                  )}

                  <Container align="end" px={0} mt={25}>
                    <Button
                      color="primary.9"
                      radius="xl"
                      onClick={handleClick}
                      loading={isLoading}
                      size="lg"
                      rightIcon={<IconChevronRight size="1.5rem" />}
                    >
                      Next
                    </Button>
                  </Container>
                </>
              ) : (
                <>
                  <Text color="dimmed" size="md" my={20}>
                    We love building meaningful relationships with our partners
                    and we're curious to learn more about you. Please share the
                    key details below to complete your registration. Your
                    journey with us starts here!
                  </Text>

                  <Select
                    label="What is your primary line of business?"
                    placeholder="Pick one"
                    size="lg"
                    radius="md"
                    required
                    data={companyType}
                    onChange={handleCompanyType}
                    value={companyCoreBusiness}
                    error={errors.companyCoreBusiness}
                  />

                  <TextInput
                    size="lg"
                    label="Registered Company Name"
                    placeholder="Wonderful Company Srl"
                    description="Note: Please indicate the type of your business.(Srl., Llc, Sa, etc..)"
                    name="companyName"
                    radius="md"
                    required
                    my="md"
                    //   error={error ? true : false}
                    color="primary"
                    onChange={handleInputChange}
                    error={errors.companyName}
                  />
                  <TextInput
                    size="lg"
                    label="Official Registered Address"
                    placeholder="Amazing Rd., 174"
                    description="The address as it appears in your company's official documents."
                    name="companyAddress"
                    radius="md"
                    required
                    my="md"
                    //   error={error ? true : false}
                    color="primary"
                    onChange={handleInputChange}
                    error={errors.companyAddress}
                  />
                  <TextInput
                    size="lg"
                    label="Postal Code/Zip Code"
                    color="primary"
                    placeholder="00100"
                    description="Enter the Zip Code of your registered address"
                    name="zipCode"
                    radius="md"
                    my="md"
                    required
                    onChange={handleInputChange}
                    error={errors.zipCode}
                    //   error={error ? true : false}
                  />
                  <TextInput
                    size="lg"
                    label="City of Registration"
                    color="primary"
                    placeholder="Rome"
                    description="The city listed in your company's official documents."
                    name="city"
                    radius="md"
                    my="md"
                    required
                    onChange={handleInputChange}
                    error={errors.city}
                    //   error={error ? true : false}
                  />
                  <TextInput
                    size="lg"
                    label="Country of Registration"
                    color="primary"
                    placeholder="Italy"
                    description="The country where your business is officially registered."
                    name="country"
                    radius="md"
                    my="md"
                    required
                    onChange={handleInputChange}
                    error={errors.country}
                    //   error={error ? true : false}
                  />
                  <TextInput
                    size="lg"
                    label="VAT number"
                    color="primary"
                    placeholder="XXXXXXXXXXXX"
                    description="Your official company VAT Number."
                    name="vat"
                    radius="md"
                    my="md"
                    required
                    onChange={handleInputChange}
                    error={errors.vat}
                    //   error={error ? true : false}
                  />
                  <TextInput
                    size="lg"
                    label="Tax Identification Number"
                    color="primary"
                    placeholder="Italian companies only"
                    description="Applicable only to Italian companies and if different from VAT number."
                    name="fiscalCode"
                    radius="md"
                    my="md"
                    onChange={handleInputChange}
                    //   error={error ? true : false}
                  />
                  <TextInput
                    size="lg"
                    label="Legal Email Address"
                    color="primary"
                    placeholder="company@legalmail.com"
                    description="The email address officially registered for your company."
                    name="legalMail"
                    radius="md"
                    my="md"
                    required
                    onChange={handleInputChange}
                    error={errors.legalMail}
                    //   error={error ? true : false}
                  />

                  <TextInput
                    size="lg"
                    label="Corporate Email"
                    placeholder="your@company.com"
                    description="Your company's primary email address."
                    name="companyEmail"
                    radius="md"
                    type="email"
                    my="md"
                    onChange={handleInputChange}
                    //   error={error ? true : false}
                  />

                  <Checkbox
                    mb={15}
                    required
                    color="primary.9"
                    label="I hereby acknowledge that I have read, understood, and consent to the data practices described in the Privacy Policy."
                    onChange={handleInputChange}
                    name="privacyPolicy"
                    error={errors.privacyPolicy}
                    key={"privacyPolicy"}
                  />
                  <Checkbox
                    required
                    color="primary.9"
                    label="By proceeding, I confirm that I have reviewed and agree to be bound by the Terms and Conditions of Service."
                    onChange={handleInputChange}
                    name="termsAndConditions"
                    error={errors.termsAndConditions}
                    key = {"termsAndConditions"}
                  />

                  {Object.keys(errors).length > 0 && (
                    <Alert color="red" mt="md" radius="lg">
                      <Flex gap="md">
                        <IconExclamationCircle
                          size={rem(25)}
                          stroke={2}
                          color={theme.colors.danger[9]}
                        />
                        <Text color="danger.9">
                          Please fix the above errors and try again
                        </Text>
                      </Flex>
                    </Alert>
                  )}

                  {error && (
                    <Alert color="red" mt="md" radius="lg">
                      <Flex gap="md">
                        <IconExclamationCircle
                          size={rem(25)}
                          stroke={2}
                          color={theme.colors.danger[9]}
                        />
                        <Text color="danger.9">{error}</Text>
                      </Flex>
                    </Alert>
                  )}

                  <Button
                    fullWidth
                    mt="xl"
                    color="primary.9"
                    radius="xl"
                    variant="outline"
                    size="lg"
                    onClick={previousStep}
                    leftIcon={<IconChevronLeft size="1rem" />}
                  >
                    Previous step
                  </Button>

                  <Button
                    fullWidth
                    mt="xl"
                    loading={isLoading}
                    onClick={handleClick}
                    color="primary.9"
                    size="lg"
                    radius="xl"
                  >
                    Create your account
                  </Button>
                </>
              )}
            </form>
          </Grid.Col>
        </Grid>
      </Box>
    </>
  );
}
