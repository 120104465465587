import { AppShell, Navbar, Header, getStylesRef, rem, createStyles, Burger, useMantineTheme, MediaQuery, Text, Flex, ThemeIcon, Badge,} from "@mantine/core";
import ClosboxLogo from "./ClosboxLogo"
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../AuthProvider/UserContext";
import { LanguageSwitcher } from "../../Localization";
import {
  IconBellRinging,
  IconFingerprint,
  IconSettings,
  IconReceipt2,
  IconLogout,
  IconUser,
} from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  header: {
    paddingBottom: theme.spacing.md,
    marginBottom: `calc(${theme.spacing.md} * 1.5)`,
    borderBottom: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
  },

  footer: {
    paddingTop: theme.spacing.md,
    marginTop: theme.spacing.md,
    borderTop: `${rem(1)} solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
  },

  link: {
    ...theme.fn.focusStyles(),
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    fontSize: theme.fontSizes.sm,
    color: theme.colors.gray[7], // colore di default modificato a danger
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,
    marginBottom:"10px",
    "&:hover": {
      backgroundColor: theme.colors.danger[6], // potresti dover scegliere un altro valore dall'array danger per avere il contrasto giusto
      color: theme.colors.danger[9],
      [`& .${getStylesRef("icon")}`]: {
        color: theme.colors.danger[9],
      },
    },
  },

  linkIcon: {
    ref: getStylesRef("icon"),
    color: theme.colors.gray[7], // colore di default modificato a danger
    marginRight: theme.spacing.sm,
  },

  linkActive: {
    backgroundColor: `${theme.colors.danger[5]} !important`,
    color: `${theme.colors.danger[9]} !important`,
    [`& .${getStylesRef("icon")}`]: {
      color: `${theme.colors.danger[9]} !important`,
    },

    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.colors.danger[5],
      }).background,
      color: theme.fn.variant({
        variant: "light",
        color: theme.colors.danger[9],
      }).color,
      [`& .${getStylesRef("icon")}`]: {
        color: theme.fn.variant({
          variant: "light",
          color: theme.colors.danger[9],
        }).color,
      },
    },
  },
}));


const data = [
  { link: "/dashboard", label: "Dashboard", icon: IconBellRinging },
  { link: "/billing", label: "Billing", icon: IconReceipt2 },
  { link: "/bookings", label: "Bookings", icon: IconFingerprint },
  { link: "/account", label: "Account", icon: IconSettings },
];




export default function Wrapper({children}) {
  let affiliationData = sessionStorage.getItem("affiliationData");
  if (affiliationData) {
    affiliationData = JSON.parse(affiliationData);
  }
  const location = useLocation();
  const { classes, cx } = useStyles();
  const { userData, logout} = useUser();
  const activeLink = data.find((item) => item.link === location.pathname);
  const [active, setActive] = React.useState(
    activeLink ? activeLink.label : "Dashboard"
  );
  const navigate = useNavigate();
  const [opened, setOpened] = React.useState(false);
  const theme = useMantineTheme();

  const toHomePage =((event)=>{
    navigate("/");
  })

  const links = data.map((item) => (
    <a
      className={cx(classes.link, {
        [classes.linkActive]: item.label === active,
      })}
      href={item.link}
      key={item.label}
      onClick={(event) => {
        event.preventDefault();
        setActive(item.label);
        navigate(item.link)
      }}
    >
      <item.icon className={classes.linkIcon} stroke={1.5} />
      <span>{item.label}</span>
    </a>
  ));
  return (
    <AppShell
      styles={{
        main: {
          background: theme.colors.gray[0],
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={
        <Navbar
          p="md"
          hiddenBreakpoint="sm"
          hidden={!opened}
          width={{ sm: 200, lg: 300 }}
        >
          <Navbar.Section grow>{links}</Navbar.Section>

          <Navbar.Section className={classes.footer}>
            <Flex align="center">
              <ThemeIcon size="lg" radius="lg" mr={10} color="danger.9">
                <IconUser />
              </ThemeIcon>
              <div>
                <Text fw={500} color="primary.9">
                  {userData.user.name}
                </Text>
                <Badge
                  color={
                    affiliationData.affiliateType === "online"
                      ? "danger"
                      : "danger"
                  }
                >
                  {affiliationData.affiliateType === "online" ? "Silver Affiliate" : "Gold Affiliate"}
                </Badge>
              </div>
            </Flex>

            <a href="/" className={classes.link} onClick={logout}>
              <IconLogout className={classes.linkIcon} stroke={1.5} />
              <span>Logout</span>
            </a>
          </Navbar.Section>
        </Navbar>
      }
      header={
        <Header height={{ base: 50, md: 70 }} p="md">
          <div
            style={{ display: "flex", alignItems: "center", justifyContent:"space-between", height: "100%" }}
          >
            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>

            <div onClick={toHomePage}>
              <ClosboxLogo size={30} />
            </div>

            <LanguageSwitcher />
          </div>
        </Header>
      }
    >
      {children}
    </AppShell>
  );
}





