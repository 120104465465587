import * as React from "react";
import  Navbar  from "../ReusableComponents/Navbar";
import HeroBanner from "../WelcomePage/HeroBanner"
import ProgramFeatures from "../WelcomePage/ProgramFeatures";
import Programs from "../WelcomePage/Programs";
import Footer from "../ReusableComponents/Footer";
import PicturesGrid from "../WelcomePage/ShopGrid";
import Contacts from "../WelcomePage/Contact";


export default function WelcomePage() {

  const footerLinks = [
    { link: "/terms-and-conditions", label: "Terms & Conditions", className:"" },
    {
      link: "https://www.iubenda.com/privacy-policy/75371987/cookie-policy",
      label: "Cookie Policy",
      className:
        "iubenda-nostyle text-decoration-none text-first iubenda-noiframe iubenda-embed iubenda-noiframe ",
    },
    {
      link: "https://www.iubenda.com/privacy-policy/75371987",
      label: "Privacy policy",
      className:
        "iubenda-nostyle text-decoration-none text-first iubenda-noiframe iubenda-embed iubenda-noiframe ",
    },
  ];


  return (
    <>
      <Navbar />
      <HeroBanner />
      <ProgramFeatures />
      <PicturesGrid/>
      <Programs/>
      <Contacts  id="contacts"/>
      <Footer links={footerLinks} />
    </>
  );
}