
import * as React from "react";
import { Image } from "@mantine/core";
import appLogo from "../../Images/App-logo-pink.png";

export default function ClosboxLogo() {
  return (
    <Image
      maw={180}
      radius="md"
      src={appLogo}
      alt="Closbox Logo"
    />
  );
}