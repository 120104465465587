import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import seoData from "../../seo.json";

const SeoHelmet = () => {
  const location = useLocation();
  const path = location.pathname.slice(1); // Rimuove la barra iniziale

  const {
    title = "",
    description = "",
  } = seoData[path] || {};

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default SeoHelmet;
